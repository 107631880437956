import React, { useEffect, useState } from "react";
import { AXIOS } from "../../Component/apiHelper";
import { apiList } from "../../Component/apiList";
import { toast } from "react-toastify";
import { Button, CircularProgress } from "@mui/material";
import moment from "moment";

const BusinessHours = ({ data, getData, businessId, edit }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tempHours, setTempHours] = useState(data);
  const [loading, setloading] = useState(false);

  const initial = Array.from({ length: 7 }, (_, index) => ({
    id: index + 1,
    user_business_id: businessId,
    day_of_week: index + 1,
    start_time: "00:00:00",
    end_time: "00:00:00",
    status: 1,
  }));

  useEffect(() => {
    if (data && data?.length === 0) {
      setTempHours(initial);
    }
  }, [data?.length, isModalOpen]);
  
  const getDayName = (dayNumber) => {
    const days = {
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thursday",
      5: "Friday",
      6: "Saturday",
      7: "Sunday",
    };
    return days[dayNumber];
  };

  const styles = {
    container: {
      border: "1px solid #febba5",
      borderRadius: "8px",
      padding: "20px",
      maxWidth: "420px",
      minWidth: "300px",
      margin: "20px 0 0",
      backgroundColor: "#ffffff",
      },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid #febba550",
      paddingBottom: "12px",
    },
    title: {
      fontSize: "18px",
      fontWeight: "600",
      color: "#fd5d29",
    },
    editButton: {
      padding: "8px 16px",
      backgroundColor: "#Fd5d29",
      color: "white",
      border: "none",
      borderRadius: "8px",
      cursor: "pointer",
    },
    dayRow: {
      display: "flex",
      justifyContent: "space-between",
      padding: "12px",
      borderBottom: "1px solid #f0f0f0",
      alignItems: "center",
    },
    dayName: {
      fontWeight: "500",
      color: "#444",
      width: "120px",
    },
    timeSlot: {
      color: "#666",
      flex: 1,
      textAlign: "right",
    },
    closed: {
      color: "#dc3545",
      fontStyle: "italic",
      flex: 1,
      textAlign: "right",
    },
    modalOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    },
    modal: {
      backgroundColor: "white",
      padding: "24px",
      borderRadius: "8px",
      width: "90%",
      maxWidth: "500px",
      maxHeight: "90vh",
      overflow: "auto",
    },
    modalHeader: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "20px",
      borderBottom: "1px solid #eee",
      paddingBottom: "10px",
    },
    timeInputGroup: {
      display: "grid",
      gridTemplateColumns: "120px 1fr 1fr auto",
      gap: "12px",
      alignItems: "center",
      marginBottom: "16px",
      padding: "8px",
      // backgroundColor: "#f8f9fa",
      // backgroundColor: "#fff2ee",
      borderRadius: "8px",
      border: "1px solid #fd5d2950",
      overflow: "auto",
    },
    timeInput: {
      padding: "8px",
      border: "1px solid #ddd",
      borderRadius: "8px",
      backgroundColor: "#fff2ee",
      width: "100%",
      color: "#fd5d29",
    },
    statusToggle: {
      display: "flex",
      alignItems: "center",
    },
    checkbox: {
      marginRight: "8px",
      width: "16px",
      height: "16px",
    },
    buttonGroup: {
      display: "flex",
      justifyContent: "flex-end",
      gap: "12px",
      marginTop: "18px",
      paddingTop: "18px",
    },
    saveButton: {
      padding: "8px 16px",
      backgroundColor: "#28a745",
      color: "white",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
    cancelButton: {
      padding: "8px 16px",
      backgroundColor: "#dc3545",
      color: "white",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
  };

  const updateHours = async () => {
    try {
      let daysHours;
      if (data?.length === 0) {
        daysHours = tempHours.map((x) => {
          return { ...x, id: 0 };
        });
      } else {
        daysHours = tempHours;
      }

      setloading(true);
      let f = new FormData();
      f.append("user_business_id", businessId);
      f.append("user_business_hours", JSON.stringify(daysHours));

      let res = await AXIOS.post(apiList.business.updateHours, f);
      if (res?.s) {
        toast.success(res?.m);
        getData();
      }
      setIsModalOpen(false);
      setloading(false);
    } catch (error) {
      setIsModalOpen(false);
      setloading(false);
      console.log(error);
    }
  };

  const handleTimeChange = (id, field, value) => {
    setTempHours((prev) =>
      prev.map((hour) => (hour.id === id ? { ...hour, [field]: value } : hour))
    );
  };

  const handleStatusToggle = (id) => {
    setTempHours((prev) =>
      prev.map((hour) =>
        hour.id === id ? { ...hour, status: hour.status === 1 ? 0 : 1 } : hour
      )
    );
  };

  const formatTime = (time) => {
    if (!time || time === "00:00:00") return "00:00";
    return time.substring(0, 5);
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h2 style={styles.title}>Business Hours</h2>

        <Button
          onClick={() => setIsModalOpen(true)}
          variant="contained"
          sx={{
            maxWidth: "100px",
            textTransform: "none",
            borderRadius: "9px",
            display: edit ? "block" : "none",
            background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
          }}
        >
          Edit
        </Button>
      </div>

      <div>
        {data?.length > 0
          ? data?.map((hour) => (
              <div key={hour.id} style={styles.dayRow}>
                <span style={styles.dayName}>
                  {getDayName(hour.day_of_week)}
                </span>
                <span
                  style={hour.status === 0 ? styles.closed : styles.timeSlot}
                >
                  {hour.status === 0
                    ? "Closed"
                    : `${moment(hour.start_time, "HH:mm").format(
                        "hh:mm A"
                      )} - ${moment(hour.end_time, "HH:mm").format("hh:mm A")}`}
                </span>
              </div>
            ))
          : initial?.map((hour) => (
              <div key={hour.id} style={styles.dayRow}>
                <span style={styles.dayName}>
                  {getDayName(hour.day_of_week)}
                </span>
                <span
                  style={hour.status === 0 ? styles.closed : styles.timeSlot}
                >
                  {hour.status === 0
                    ? "Closed"
                    : `${formatTime(hour.start_time)} - ${formatTime(
                        hour.end_time
                      )}`}
                </span>
              </div>
            ))}
      </div>

      {isModalOpen && (
        <div style={styles.modalOverlay} onClick={() => setIsModalOpen(false)}>
          <div style={styles.modal} onClick={(e) => e.stopPropagation()}>
            <div style={styles.modalHeader}>
              <h3 style={{ color: "#fd5d29" }}>Edit Business Hours</h3>
            </div>

            {tempHours?.map((hour) => (
              <div key={hour.id} style={styles.timeInputGroup}>
                <div style={styles.dayName}>{getDayName(hour.day_of_week)}</div>
                <input
                  type="time"
                  value={formatTime(hour.start_time)}
                  onChange={(e) =>
                    handleTimeChange(hour.id, "start_time", e.target.value)
                  }
                  disabled={hour.status === 0}
                  style={styles.timeInput}
                />
                <input
                  type="time"
                  value={formatTime(hour.end_time)}
                  onChange={(e) =>
                    handleTimeChange(hour.id, "end_time", e.target.value)
                  }
                  disabled={hour.status === 0}
                  style={styles.timeInput}
                />
                <div style={styles.statusToggle}>
                  <input
                    type="checkbox"
                    checked={hour.status === 1}
                    onChange={() => handleStatusToggle(hour.id)}
                    style={styles.checkbox}
                  />
                  <span>Open</span>
                </div>
              </div>
            ))}

            <div style={styles.buttonGroup}>
              <Button
                sx={{
                  width: "100%",
                  textTransform: "none",
                  borderRadius: "15px",
                  border: "1px solid #FF602D",
                  // background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
                  color: "#FF602D",
                  "&:hover": {
                    background: "#00075",
                  },
                }}
                onClick={() => {
                  setTempHours(data);
                  setIsModalOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={updateHours}
                disabled={loading}
                sx={{
                  width: "100%",
                  maxWidth: "300px",
                  textTransform: "none",
                  borderRadius: "15px",
                  background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
                  "&:hover": {
                    background: "#00075",
                  },
                }}
              >
                {loading ? <CircularProgress size={28} /> : "Update"}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BusinessHours;
