export const RouteList = {
  login: "/login",
  dashboard: "/",
  user: "/user",
  business: "/business",
  content: "/content",
  inquiry: "/inquiry",
  notification: "/notification",
  userDetails: "/user/details",
  businessDetails: "/business/details",
  category: "/category",
  adminRole: "/role",
  list: "/list",
  listDetails: "/list/details",
  help: "/help",
  Subscription: "/payment",
  review: "/review",
  matrics: "/matrics",
  report:"/report",
  userreportdetails: "/UserReportDetails"
};
