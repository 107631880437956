import { Box, Typography } from "@mui/material";
import AnalyticCards from "./AnalyticCards";
import RecentUserComponent from "./RecentUserComponent";
import RecentBusinessComponent from "./RecentBusinessComponent";
import { useNavigate } from "react-router-dom";
import { RouteList } from "../../Component/RoutesList";

const DashboardScreen = () => {
  const nav = useNavigate();
  const accesslist = JSON.parse(
    localStorage.getItem("Megalopolist-userData-roles")
  );
  const firstModule = accesslist?.find((module) => module.id === 1);
  const recent_user_access = !!firstModule?.access?.find(
    (access) => access.id === 1 && access.admin_user_id !== null
  );
  const recent_business_access = !!firstModule?.access?.find(
    (access) => access.id === 2 && access.admin_user_id !== null
  );
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        gap: 1.5,
      }}
    >
      <Typography>
        <h2> Dashboard </h2>
      </Typography>
      <AnalyticCards />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          height: "calc(100vh - 280px)",
          gap: { xs: 4, md: 4, lg: 1 },
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: recent_user_access ? "block" : "none",
            width: { xs: "100%", lg: "49.5%" },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", px: 1 }}>
            <h4>Recent Users</h4>
            <h5
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                fontWeight: "400",
              }}
              onClick={() => nav(RouteList.user)}
            >
              View All
            </h5>
          </Box>
          <RecentUserComponent />
        </Box>
        <Box
          sx={{
            display: recent_business_access ? "block" : "none",
            width: { xs: "100%", lg: "49.5%" },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", px: 1 }}>
            <h4>Recent Business</h4>
            <h5
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                fontWeight: "400",
              }}
              onClick={() => nav(RouteList.business)}
            >
              View All
            </h5>
          </Box>
          <RecentBusinessComponent />
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardScreen;
