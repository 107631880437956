import { Box, Typography } from "@mui/material";
import DataListingComponent from "../../Component/DataListGrid";
import { useEffect, useRef, useState } from "react";
import DropDownComponent from "../../Component/DropDownComponent";
import { AXIOS } from "../../Component/apiHelper";
import { apiList } from "../../Component/apiList";
import defaultUserImage from "../../Assets/Image/default.webp";
import { toast } from "react-toastify";
import logo from "../../Assets/Image/question.webp";
import ConfirmationModal1 from "../../Component/ConfirmModal1";
// import { useNavigate } from "react-router-dom";
// import { RouteList } from "../../Component/RoutesList";
// import app from "../../Assets/Image/User Block-1.webp";
// import rej from "../../Assets/Image/User Block.webp";
import dlt from "../../Assets/Image/delete.webp";
import RatingStar from "../../Component/RatingStarComponent";
import moment from "moment";

const ReviewScreen = () => {
  const PAGE_SIZE_OPTIONS = [15];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalData, setTotalData] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  // const navigate = useNavigate();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [filter, setFilter] = useState(99);
  const [open, setOpen] = useState(false);
  const [actionParams, setActionParams] = useState("");

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleAction = async (id) => {
    try
    {
      const res = await AXIOS.get(`${apiList.rating.deleteById}?id=${id}`);
      if (res?.s)
      {
        toast.error("Deleted successfully");
        getData(
          page,
          rowsPerPage,
          filter,
          filterRef.current,
          debouncedSearchTerm,
          searchRef.current
        );
      } else
      {
        toast.error(res?.m);
      }
    } catch (error)
    {
      console.log(error);
    }
  };

  const filterOption = [
    { label: "All", value: 9 },
    { label: "5", value: "4" },
    { label: "4", value: "3" },
    { label: "3", value: "2" },
    { label: "2", value: "1" },
    { label: "1", value: "0" },
  ];

  const columns = [

    {
      field: "rowid",
      headerName: "Sr.",
      width: 60,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        return cell?.row?.rowid + page * rowsPerPage;
      },
    },
    {
      field: "profile_img",
      headerName: "Profile",
      width: 90,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img
            src={
              cell?.row?.user_details?.profile_img
                ? apiList.baseURL + cell?.row?.user_details?.profile_img
                : defaultUserImage
            }
            alt="profile"
            style={{ borderRadius: "12px", height: "40px", width: "40px" }}
          />
        </div>
      ),
    },

    {
      field: "username",
      headerName: "User Name",
      minWidth: 200,
      maxWidth: 300,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
      renderCell: (cell) => cell?.row?.user_details?.username,
    },

    {
      field: "review",
      headerName: "Reviews",
      minWidth: 160,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
    },
    {
      field: "business_name",
      headerName: "Business Name",
      minWidth: 200,
      maxWidth: 300,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
      renderCell: (cell) => cell?.row?.user_business?.business_name ?? "-",
    },
    {
      field: "rating",
      headerName: "Rating",
      width: 160,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: ({ row }) => {
        return <RatingStar rating={row?.rating_point ?? 0} />;
      },
    },
    {
      field: "created_at",
      headerName: "Date",
      width: 160,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: ({ row }) => {
        return moment(row?.created_at).local().format("lll");
      },
    },

    {
      field: "status",
      headerName: "Action",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        const s = cell?.row?.status;
        return (
          <Box className="flexCenter" sx={{ height: "100%" }}>
            {/* {s === 1 ? (
              <Box className="flexCenter" sx={{ height: "100%", gap: 1.5 }}>
                <img
                  src={rej}
                  alt="Block"
                  style={{ height: "25px", width: "25px" }}
                  // onClick={() =>
                  //     handleBusinessAction(cell?.row?.user_details?.id, 1)
                  // }
                />
                <img
                  src={app}
                  style={{ height: "25px", width: "25px" }}
                  alt="View"
                  // onClick={() =>
                  //     handleBusinessAction(cell?.row?.user_details?.id, -1)
                  // }
                />
              </Box>
            ) : s === 0 ? (
              <span
                className="flexCenter"
                style={{
                  background: "#159300",
                  color: "#FFF",
                  py: 2,
                  px: 4,
                  width: "100px",
                  height: "33px",
                  borderRadius: "8px",
                }}
              >
                Approved
              </span>
            ) : (
              s === -1 && (
                <span
                  className="flexCenter"
                  style={{
                    background: "#D00C00",
                    color: "#FFF",
                    py: 2,
                    px: 4,
                    width: "100px",
                    height: "33px",
                    borderRadius: "8px",
                  }}
                >
                  Rejected
                </span>
              )
            )} */}
            <Box className="flexCenter" sx={{ height: "100%", gap: 1.5 }}>
              <img
                src={dlt}
                alt="Block"
                style={{ height: "25px", width: "25px", cursor: "pointer" }}
                onClick={() => {
                  setActionParams(cell?.row?.id);
                  setOpen(true);
                }}
              />
            </Box>
          </Box>
        );
      },
    },
  ];

  const getData = async (
    page = 0,
    rowsPerPage = PAGE_SIZE_OPTIONS[0],
    filter,
    prevFilter,
    search,
    prevSearch
  ) => {
    try
    {
      const count = page * rowsPerPage;
      setLoading(true);
      if (filter !== prevFilter || search !== prevSearch)
      {
        setPage(0);
        setData([]);
      }
      let arg = `?skip=${count}&limit=${rowsPerPage}`;
      if (search?.length > 0)
      {
        arg += `&search=${search?.trim()}`;
      }

      if (filter !== 9 && filter !== 99)
      {
        arg += `&rating_point=${filter}`;
      }

      const res = await AXIOS.get(`${apiList.rating.getAll}${arg}`);
      if (res?.s)
      {
        const updatedData = res?.r;
        const serialData = updatedData?.map((x, i) => {
          return { ...x, rowid: i + 1 };
        });
        setData(serialData);
        page === 0 && setTotalData(res?.total ?? 0);
        setLoading(false);
      } else
      {
        setData([]);
        setPage();
        toast.warn(res?.m);
        setLoading(false);
      }
    } catch (error)
    {
      setLoading(false);
      console.log(error);
    }
  };

  const searchRef = useRef(debouncedSearchTerm);
  const filterRef = useRef(filter);

  useEffect(() => {
    getData(
      page,
      rowsPerPage,
      filter,
      filterRef.current,
      debouncedSearchTerm,
      searchRef.current
    );
    searchRef.current = debouncedSearchTerm;
    filterRef.current = filter;
  }, [debouncedSearchTerm, filter, rowsPerPage, page]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box className="flexBetween">
        <Typography>
          <h2>Review & Rating Management </h2>
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: "470px",
            width: "100%",
          }}
        >
          <input
            type="search"
            name="search"
            value={searchTerm?.trimStart()}
            placeholder="Search by name"
            style={{
              padding: "13px",
              height: "100%",
              borderRadius: "12px",
              border: "1px solid #FD5D29",
              backgroundColor: "#FFF6F3",
              color: "#FD5D29",
              width: "100%",
            }}
            onChange={(e) => setSearchTerm(e.target.value?.trimStart())}
            id="search"
          />
        </Box>
        <Box sx={{}}>
          <DropDownComponent
            value={filter}
            options={filterOption}
            modal={false}
            handleChange={handleFilterChange}
            placeholder={"Rating"}
            check={false}
          />
        </Box>
      </Box>
      <Box sx={{ height: "calc(100vh - 180px)" }}>
        <DataListingComponent
          data={data}
          PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS}
          loading={loading}
          totalData={totalData}
          page={page}
          columns={columns}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />

        <ConfirmationModal1
          open={open}
          setOpen={setOpen}
          logo={logo}
          onConfirm={() => handleAction(actionParams)}
          title="Confirm Delete Action"
          message="Are you sure want to delete?"
          confirmText="Confirm"
          cancelText="Cancel"
        />
      </Box>
    </Box>
  );
};

export default ReviewScreen;
