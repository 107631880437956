import React from "react";
import { apiList } from "../../Component/apiList";

const DocumentVerification = ({ data }) => {
  const defaultImage = "/api/placeholder/105/105";

  const documentImage = data?.document
    ? `${apiList.baseURL}${data.document}`
    : defaultImage;

  const selfieImage = data?.selfie
    ? `${apiList.baseURL}${data.selfie}`
    : defaultImage;

  const styles = {
    container: {
      maxWidth: "800px",
      margin: "0 auto",
      padding: "20px",
      border: "1px solid #febba5",
      borderRadius: "8px",
      //   boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      backgroundColor: "#ffffff",
      marginTop: "20px",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid #febba550",
      paddingBottom: "12px",
      marginBottom: "12px",
    },
    title: {
      fontSize: "18px",
      fontWeight: "600",
      color: "#fd5d29",
      // borderBottom: "1px solid #febba550",
    },
    imagesContainer: {
      display: "flex",
      flexDirection: "row",
      gap: "20px",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    imageBox: {
      border: "1px solid #e0e0e0",
      borderRadius: "8px",
      padding: "10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "180px",
      backgroundColor: "#fff6f3",
    },
    imageWrapper: {
      border: "2px solid #e0e0e0",
      borderRadius: "12px",
      overflow: "hidden",
      width: "155px",
      height: "155px",
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      cursor: "pointer",
    },
    label: {
      marginTop: "5px",
      fontSize: "14px",
      fontWeight: "500",
      color: "#666666",
    },
    buttonsContainer: {
      display: "flex",
      justifyContent: "center",
      gap: "15px",
      marginTop: "25px",
    },
    button: {
      padding: "10px 20px",
      borderRadius: "6px",
      border: "none",
      cursor: "pointer",
      fontSize: "14px",
      fontWeight: "500",
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    approveButton: {
      backgroundColor: "#4CAF50",
      color: "white",
      "&:hover": {
        backgroundColor: "#45a049",
      },
    },
    rejectButton: {
      backgroundColor: "#f44336",
      color: "white",
      "&:hover": {
        backgroundColor: "#da190b",
      },
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h2 style={styles.title}>Documents</h2>
      </div>

      <div style={styles.imagesContainer}>
        {/* Government ID */}
        <div style={styles.imageBox}>
          <div style={styles.imageWrapper}>
            <img
              src={documentImage}
              alt="Government ID"
              style={styles.image}
              onClick={() => window.open(documentImage)}
            />
          </div>
          <div style={styles.label}>Gov. ID</div>
        </div>

        {/* User Selfie */}
        <div style={styles.imageBox}>
          <div style={styles.imageWrapper}>
            <img
              src={selfieImage}
              alt="User Selfie"
              style={styles.image}
              onClick={() => window.open(selfieImage)}
            />
          </div>
          <div style={styles.label}>User Selfie</div>
        </div>
      </div>

      {/* <div style={styles.buttonsContainer}>
        <button
          onClick={() => {}}
          style={{ ...styles.button, ...styles.approveButton }}
        >
          Approve
        </button>
        <button
          onClick={() => {}}
          style={{ ...styles.button, ...styles.rejectButton }}
        >
          Reject
        </button>
      </div> */}
    </div>
  );
};

export default DocumentVerification;
