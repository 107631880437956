import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import logo from "../Assets/Image/question.webp";
import { CircularProgress } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  width: "calc(100% - 50px)",
  bgcolor: "background.paper",
  outline: "none",
  boxShadow: 24,
  borderRadius: "12px",
  p: 4,
};

export default function ConfirmationModal1({
  handleOpenButton,
  title = "Confirm Action",
  message = "Are you sure?",
  confirmText = "Confirm",
  cancelText = "Cancel",
  onConfirm,
  onCancel,
  open,
  setOpen,
  loading,
}) {
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    if (typeof onCancel === "function") onCancel();
    setOpen(false);
  };

  const handleConfirm = () => {
    if (typeof onConfirm === "function") onConfirm();
    !loading && setOpen(false);
  };

  return (
    <div>
      <Box onClick={handleOpen}>{handleOpenButton && handleOpenButton()}</Box>
      <Modal
        aria-labelledby="confirmation-modal-title"
        aria-describedby="confirmation-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 300,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={{ ...style, textAlign: "center" }}>
            <Box className="flexCenter" sx={{ mb: "0.5rem" }}>
              <img src={logo} alt="logo" style={{ height: "100px" }} />
            </Box>
            <Typography
              id="confirmation-modal-title"
              variant="h6"
              component="h2"
              sx={{
                fontSize: "1.25rem",
                fontWeight: "bold",
                color: "#333",
              }}
            >
              {title}
            </Typography>
            <Typography id="confirmation-modal-description" sx={{ mt: 2 }}>
              {message}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 1,
                mt: 2,
              }}
            >
              <Button
                sx={{
                  width: "100%",
                  maxWidth: "300px",
                  textTransform: "none",
                  borderRadius: "15px",
                  border: "1px solid #FF602D",
                  // background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
                  color: "#FF602D",
                  "&:hover": {
                    background: "#00075",
                  },
                }}
                onClick={handleClose}
              >
                {cancelText}
              </Button>
              <Button
                variant="contained"
                onClick={handleConfirm}
                sx={{
                  width: "100%",
                  maxWidth: "300px",
                  textTransform: "none",
                  borderRadius: "15px",
                  background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
                  "&:hover": {
                    background: "#00075",
                  },
                }}
              >
                {loading ? <CircularProgress /> : confirmText}
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
