import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import QuillEditerComponent from "../../Component/QuillEditerComponent";
import { useEffect } from "react";
import { AXIOS } from "../../Component/apiHelper";
import { apiList } from "../../Component/apiList";

const ContentScreen = () => {
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const ref = useRef();

  const formik = useFormik({
    initialValues: { content: "" },
    validationSchema: Yup.object({
      content: Yup.string().required("Content is required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("content", values.content);
        const url =
          currentTab === 0
            ? apiList.content.updatePrivacy
            : apiList.content.updateTerms;
        const res = await AXIOS.post(url, formData);
        if (res?.s) {
          toast.success("Updated successfully");
          getData(currentTab);
        }
        setLoading(false);
      } catch (error) {
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  const getData = async (currentTab) => {
    try {
      setLoading(true);
      const url =
        currentTab === 0
          ? apiList.content.getPrivacy
          : apiList.content.getTerms;
      const res = await AXIOS.get(url);
      if (res?.s) {
        formik.setFieldValue("content", res?.r?.content);
        setLoading(false);
      } else {
        toast.error(res?.m);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
    formik.resetForm();
    getData(tab);
  };

  useEffect(() => {
    getData(currentTab);
  }, []);
  return (
    <>
      <Box className="flexBetween">
        <Typography>
          <h2> Content Management </h2>
        </Typography>
      </Box>
      <Box
        sx={{
          px: 1,
          py: 2,
          display: "flex",
          gap: 2,
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            color: currentTab === 0 ? "#FD5D29" : "grey",
            fontWeight: currentTab === 0 ? "600" : "400",
            // textDecoration: currentTab === 0 ? "#FD5D2945" : "#FFF",
            textDecoration: currentTab === 0 ? "underline" : "none",
            // py: 0.5,
            borderRadius: "12px",
            cursor: "pointer",
          }}
          onClick={() => handleTabChange(0)}
        >
          Privacy Policy
        </Box>
        <Box
          sx={{
            color: currentTab === 1 ? "#FD5D29" : "grey",
            fontWeight: currentTab === 1 ? "600" : "400",
            textDecoration: currentTab === 1 ? "underline" : "none",
            // py: 0.5,
            borderRadius: "12px",
            cursor: "pointer",
          }}
          onClick={() => handleTabChange(1)}
        >
          Terms & Conditions
        </Box>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <QuillEditerComponent formik={formik} ref={ref} loading={loading} />

        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            mt: "15px", // Add margin-top to separate button from content
          }}
        >
          <Button
            type="submit"
            variant="contained"
            disabled={
              loading ||
              formik.isSubmitting ||
              !formik?.values?.content?.replace(/<[^>]+>/g, "")?.trimStart()
            }
            sx={{
              width: "150px",
              textTransform: "none",
              borderRadius: "12px",
              background: "#FD5D29",
              "&:hover": {
                background: `#FD5D29`,
              },
              "&:disabled": {
                background: "#FD5D2990",
                color: "#FFF",
              },
            }}
          >
            {formik.isSubmitting ? "Submitting..." : "Submit"}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default ContentScreen;
