import React, { useState } from "react";
import { apiList } from "../../Component/apiList";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Button, CircularProgress, TextField } from "@mui/material";
import def_image from "../../Assets/Image/default.webp";
import email from "../../Assets/Image/email.webp";
import phno from "../../Assets/Image/phone.webp";
import web from "../../Assets/Image/web.webp";
import location from "../../Assets/Image/location.webp";
import { AXIOS } from "../../Component/apiHelper";
import { toast } from "react-toastify";
import zIndex from "@mui/material/styles/zIndex";

const BasicInformation = ({ data, getData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tempData, setTempData] = useState(data);
  const [newLogo, setNewLogo] = useState(null);
  const [previewLogo, setPreviewLogo] = useState(null);
  const [loading, setloading] = useState(false);

  const defaultImage = def_image;
  const profileImage = data?.business_logo
    ? `${apiList.baseURL}${data.business_logo}`
    : defaultImage;

  const styles = {
    container: {
      maxWidth: "800px",
      width: "100%",
      padding: "20px",
      border: "1px solid #febba5",
      borderRadius: "8px",
      // boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      backgroundColor: "#ffffff",
      marginTop: "20px",
      position: "relative",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid #febba550",
      paddingBottom: "12px",
      marginBottom: "12px",
    },
    title: {
      fontSize: "18px",
      fontWeight: "600",
      color: "#fd5d29",
    },
    profileSection: {
      display: "flex",
      gap: "16px",
      flexWrap: "wrap",
      marginTop: "10px 0",
    },
    imageContainer: {
      flexShrink: 0,
    },
    image: {
      borderRadius: "12px",
      height: "105px",
      width: "105px",
      objectFit: "cover",
    },
    infoContainer: {
      flex: "1",
      minWidth: "250px",
    },
    businessName: {
      fontSize: "16px",
      fontWeight: "600",
      marginBottom: "8px",
      color: "#333333",
    },
    description: {
      fontSize: "14px",
      color: "#666666",
      lineHeight: "1.5",
      marginTop: "5px",
      maxHeight: "150px",
      overflow: "auto",
    },
    contactRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "16px",
      flexWrap: "wrap",
      marginBottom: "12px",
      width: "100%",
    },
    link: {
      textDecoration: "none",
      color: "#333333",
      cursor: "pointer",
      fontSize: "14px",
      padding: "4px 8px",
      borderRadius: "4px",
      transition: "background-color 0.2s ease",
      "&:hover": {
        backgroundColor: "#f5f5f5",
      },
    },
    editButton: {
      padding: "8px 16px",
      backgroundColor: "#Fd5d29",
      color: "white",
      border: "none",
      borderRadius: "8px",
      cursor: "pointer",
    },
    modalOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    },
    modal: {
      backgroundColor: "white",
      padding: "24px",
      borderRadius: "8px",
      width: "90%",
      maxWidth: "600px",
      maxHeight: "90vh",
      overflow: "auto",
      zIndex: 10,
    },
    modalHeader: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "20px",
      borderBottom: "1px solid #eee",
      paddingBottom: "10px",
    },
    formGroup: {
      // marginBottom: "16px",
      width: "100%",
    },
    label: {
      display: "block",
      // marginBottom: "8px",
      fontWeight: "500",
      color: "#333",
    },
    input: {
      width: "100%",
      padding: "8px 12px",
      border: "1px solid #ddd",
      borderRadius: "4px",
      fontSize: "14px",
    },
    textarea: {
      width: "100%",
      padding: "8px 12px",
      border: "1px solid #ddd",
      borderRadius: "4px",
      fontSize: "14px",
      minHeight: "100px",
      resize: "vertical",
    },
    buttonGroup: {
      display: "flex",
      justifyContent: "flex-end",
      gap: "12px",
      marginTop: "10px",
      paddingTop: "10px",
    },
    saveButton: {
      padding: "8px 16px",
      backgroundColor: "#28a745",
      color: "white",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
    cancelButton: {
      padding: "8px 16px",
      backgroundColor: "#dc3545",
      color: "white",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
    imageUpload: {
      display: "flex",
      alignItems: "center",
      gap: "16px",
      marginBottom: "16px",
      justifyContent: "start",
    },
    uploadButton: {
      padding: "8px 16px",
      backgroundColor: "#6c757d",
      color: "white",
      border: "none",
      fontSize: "14px",
      borderRadius: "8px",
      cursor: "pointer",
      display: "flex",
      alignItems: "end",
    },
    previewImage: {
      width: "105px",
      height: "105px",
      borderRadius: "12px",
      objectFit: "cover",
    },
    phoneGroup: {
      display: "flex",
      gap: "12px",
    },
    countryCode: {
      width: "80px",
    },
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewLogo(file);
      setPreviewLogo(URL.createObjectURL(file));
    }
  };

  const validationSchema = Yup.object({
    business_name: Yup.string().required("Business name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phno: Yup.string().required("Phone number is required"),
    phno_cc: Yup.string().required("Country code is required"),
    website: Yup.string().required("Website is required"),
    address: Yup.string().required("Address is required"),
    description: Yup.string().required("Description is required"),
  });

  const formik = useFormik({
    initialValues: {
      business_name: data?.business_name || "",
      email: data?.email || "",
      phno: data?.phno || "",
      phno_cc: data?.phno_cc || "",
      website: data?.website || "",
      address: data?.address || "",
      description: data?.description || "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("user_business_id", data?.id);
      formData.append("business_name", values?.business_name);
      formData.append("email", values?.email);
      formData.append("phno_cc", values?.phno_cc);
      formData.append("phno", values?.phno);
      formData.append("website", values?.website);
      formData.append("address", values?.address);
      formData.append("description", values?.description);
      if (newLogo) {
        formData.append("business_logo", newLogo);
      }

      try {
        setloading(true);
        let res = await AXIOS.post(apiList.business.updateBusiness, formData);
        if (res?.s) {
          getData();
          setIsModalOpen(false);
          toast.success(res?.m);
        }
        setloading(false);
      } catch (error) {
        console.log(error);
        setloading(false);
      }
      setloading(false);
      setIsModalOpen(false);
    },
  });

  const inputStyles = {
    margin: "0.25rem 0 0 0",
    ".MuiInputBase-root": {
      borderRadius: "15px",
      background: "#FD5D2914",
      color: "#000",
      border: "1px solid #FD5D2966",
    },
    ".MuiOutlinedInput-input": {
      padding: "14px",
    },
    "& .MuiTextField-root": {
      width: "100%",
    },
    // ".MuiInputBase-root:hover": {
    //   background: "#FF8862",
    //   color: "#fff",
    // },
    "& input[type='password']::-ms-reveal": {
      display: "none",
    },
    "& input[type='password']::-ms-clear": {
      display: "none",
    },
    ".MuiOutlinedInput-input:focus": {
      outline: "none",
    },
    fieldset: {
      border: "none",
    },
  };

  // const handleInputChange = (field, value) => {
  //   setTempData((prev) => ({
  //     ...prev,
  //     [field]: value,
  //   }));
  // };

  const errorMessageStyles = {
    fontSize: "12px",
    px: 1.5,
    letterSpacing: "0.03rem",
    py: 0.25,
    height: "18px",
    color: "#d32f2f",
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
  };

  return (
    <>
      <div style={styles.container}>
        <div style={styles.header}>
          <h2 style={styles.title}>Basic Information</h2>

          <Button
            onClick={() => {
              setIsModalOpen(true);
              formik.setValues(data);
            }}
            variant="contained"
            sx={{
              textTransform: "none",
              width: "70px",
              borderRadius: "9px",
              background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
            }}
          >
            Edit
          </Button>
        </div>

        <div style={styles.profileSection}>
          <div style={styles.imageContainer}>
            <img src={profileImage} alt="Profile" style={styles.image} />
          </div>

          <div style={styles.infoContainer}>
            <div style={styles.businessName}>{data?.business_name}</div>
            <div style={styles.contactRow}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <img src={email} height={18} width={18} alt="" />
                <a
                  href={`mailto:${data?.email}`}
                  style={styles.link}
                  onMouseOver={(e) =>
                    (e.target.style.backgroundColor = "#f5f5f5")
                  }
                  onMouseOut={(e) =>
                    (e.target.style.backgroundColor = "transparent")
                  }
                >
                  {data?.email}
                </a>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <img src={phno} height={18} width={18} alt="" />
                <a
                  href={`tel:+${data?.phno_cc}${data?.phno}`}
                  style={styles.link}
                  onMouseOver={(e) =>
                    (e.target.style.backgroundColor = "#f5f5f5")
                  }
                  onMouseOut={(e) =>
                    (e.target.style.backgroundColor = "transparent")
                  }
                >
                  {data?.phno_cc} {data?.phno}
                </a>
              </Box>
            </div>

            <div style={styles.contactRow}>
              <Box
                sx={{
                  display: data?.website ? "flex" : "none",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <img src={web} height={18} width={18} alt="" />
                <a
                  href={`http://${data?.website}`}
                  style={styles.link}
                  onMouseOver={(e) =>
                    (e.target.style.backgroundColor = "#f5f5f5")
                  }
                  onMouseOut={(e) =>
                    (e.target.style.backgroundColor = "transparent")
                  }
                >
                  {data?.website ?? "-"}
                </a>
              </Box>
              <Box
                sx={{
                  display: data?.address ? "flex" : "none",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <img src={location} height={18} width={18} alt="" />
                <a
                  href={`https://maps.google.com/?q=${data?.lat},${data?.lang}`}
                  target="_blank"
                  rel="noreferrer"
                  style={styles.link}
                  onMouseOver={(e) =>
                    (e.target.style.backgroundColor = "#f5f5f5")
                  }
                  onMouseOut={(e) =>
                    (e.target.style.backgroundColor = "transparent")
                  }
                >
                  {data?.address ?? "-"}
                </a>
              </Box>
            </div>
          </div>
        </div>
        <Box>
          <p>Description</p>
          <div style={styles.description}>{data?.description}</div>
        </Box>
      </div>
      {isModalOpen && (
        <div style={styles.modalOverlay} onClick={() => setIsModalOpen(false)}>
          <form
            onSubmit={formik.handleSubmit}
            style={styles.modal}
            onClick={(e) => e.stopPropagation()}
          >
            <div style={styles.modalHeader}>
              <h3 style={{ color: "#fd5d29" }}> Edit Basic Information</h3>
            </div>

            <div style={styles.imageUpload}>
              <img
                src={previewLogo || profileImage}
                alt="Business Logo"
                style={styles.previewImage}
              />
              <input
                type="file"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    setNewLogo(file);
                    setPreviewLogo(URL.createObjectURL(file));
                  }
                }}
                style={{ display: "none" }}
                id="logo-upload"
              />
              <label htmlFor="logo-upload" style={styles.uploadButton}>
                Change Logo
              </label>
            </div>

            <div style={styles.formGroup}>
              <label style={styles.label}>Business Name</label>
              <TextField
                fullWidth
                name="business_name"
                value={formik.values.business_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.business_name &&
                  Boolean(formik.errors.business_name)
                }
                variant="outlined"
                focused
                sx={inputStyles}
                placeholder="Enter business name"
              />
              <Box sx={errorMessageStyles}>
                {formik.touched.business_name && formik.errors.business_name}
              </Box>
            </div>

            <div style={styles.formGroup}>
              <label style={styles.label}>Email</label>
              <TextField
                fullWidth
                name="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                variant="outlined"
                focused
                sx={inputStyles}
                placeholder="Enter email"
              />
              <Box sx={errorMessageStyles}>
                {formik.touched.email && formik.errors.email}
              </Box>
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Website</label>
              <TextField
                fullWidth
                name="website"
                type="text"
                value={formik.values.website}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.website && Boolean(formik.errors.website)}
                variant="outlined"
                focused
                sx={inputStyles}
                placeholder="Enter website"
              />
              <Box sx={errorMessageStyles}>
                {formik.touched.website && formik.errors.website}
              </Box>
            </div>

            <div style={styles.formGroup}>
              <label style={styles.label}>Address</label>
              <TextField
                fullWidth
                name="address"
                type="text"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.address && Boolean(formik.errors.address)}
                variant="outlined"
                focused
                sx={inputStyles}
                placeholder="Enter address"
              />
              <Box sx={errorMessageStyles}>
                {formik.touched.address && formik.errors.address}
              </Box>
            </div>

            <div style={styles.formGroup}>
              <label style={styles.label}>Phone</label>
              <div style={styles.phoneGroup}>
                <TextField
                  // style={{ ...styles.input, ...styles.countryCode }}
                  sx={{
                    ...inputStyles,
                    width: "80px",
                  }}
                  type="tel"
                  value={formik.values.phno_cc || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.phno_cc && Boolean(formik.errors.phno_cc)
                  }
                  placeholder="Code"
                  name="phno_cc"
                />
                <TextField
                  // style={styles.input}
                  sx={inputStyles}
                  type="tel"
                  name="phno"
                  value={formik.values.phno || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phno && Boolean(formik.errors.phno)}
                  placeholder="Phone number"
                />
              </div>
              <Box sx={errorMessageStyles}>
                {(formik.touched.phno && formik.errors.phno) ||
                  (formik.touched.phno_cc && formik.errors.phno_cc)}
              </Box>
            </div>

            <div style={styles.formGroup}>
              <label style={{ ...styles.label, marginBottom: "8px" }}>
                Description
              </label>
              <TextField
                fullWidth
                name="description"
                value={formik.values.description || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                multiline
                rows={4} // Adjust the number of rows as needed
                variant="outlined"
                focused
                sx={{
                  ...inputStyles,
                  ".MuiOutlinedInput-input": {
                    padding: "0",
                  },
                }}
                placeholder="Enter description"
              />
              <Box sx={errorMessageStyles}>
                {formik.touched.description && formik.errors.description}
              </Box>
            </div>

            <div style={styles.buttonGroup}>
              <Button
                sx={{
                  width: "100%",
                  textTransform: "none",
                  borderRadius: "15px",
                  border: "1px solid #FF602D",
                  // background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
                  color: "#FF602D",
                  "&:hover": {
                    background: "#00075",
                  },
                }}
                onClick={() => {
                  setTempData(data);
                  setIsModalOpen(false);
                  setPreviewLogo(null);
                  setNewLogo(null);
                  formik.resetForm();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={formik.handleSubmit}
                disabled={loading}
                sx={{
                  width: "100%",
                  maxWidth: "300px",
                  textTransform: "none",
                  borderRadius: "15px",
                  background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
                  "&:hover": {
                    background: "#00075",
                  },
                }}
              >
                {loading ? <CircularProgress size={28} /> : "Update"}
              </Button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default BasicInformation;
