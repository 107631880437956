import React from "react";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import StarHalfRoundedIcon from "@mui/icons-material/StarHalfRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import { Box } from "@mui/material";

export default function RatingStar({ rating }) {
    const getStars = (rating) => {
        const fullStars = Math.floor(rating);
        const halfStar = rating % 1 >= 0.5 ? 1 : 0;
        const emptyStars = 5 - fullStars - halfStar;

        return (
            <>
                {[...Array(fullStars)].map((_, i) => (
                    <StarRateRoundedIcon
                        style={{ fontSize: "22px", color: "#FFB53D" }}
                        key={`full-${i}`}
                    />
                ))}
                {halfStar === 1 && (
                    <StarHalfRoundedIcon style={{ fontSize: "22px", color: "#FFB53D" }} />
                )}
                {[...Array(emptyStars)].map((_, i) => (
                    <StarOutlineRoundedIcon
                        style={{ fontSize: "22px", color: "#FFB53D" }}
                        key={`empty-${i}`}
                    />
                ))}
            </>
        );
    };

    return <Box sx={{ display: "flex", pb: "0rem" }}>{getStars(rating)}</Box>;
}
