import { Button, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AXIOS } from "../../Component/apiHelper";
import { apiList } from "../../Component/apiList";
import { toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Loader from "../../Component/Loader";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteList } from "../../Component/RoutesList";
import defaultUser from "../../Assets/Image/default.webp";
import moment from "moment";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const PostDetailsModal = ({ id, setOpen, open }) => {
  const handleClose = () => {
    setOpen(false);
    setMedia([]);
    setData();
  };
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [media, setMedia] = useState([]);
  const [currentMedia, setCurrentMedia] = useState(0);


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 500,
    bgcolor: "background.paper",
    borderRadius: "15px",
    boxShadow: 24,
    maxHeight: "calc(100vh - 50px)",
    overflowX: "auto",
    p: 3,
  };

  const getDetailsById = async (id) => {
    try
    {
      setLoading(true);
      const res = await AXIOS.get(`${apiList.list.detailsById}?feed_id=${id}`);
      if (res?.s)
      {
        setData(res?.r);
        setMedia(res?.r?.feed_media);
      } else
      {
        toast.error(res?.m);
      }
      setLoading(false);
    } catch (error)
    {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    id && getDetailsById(id);
  }, [id]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 300,
          },
        }}
        sx={{
          border: "none",
        }}
      >
        <Fade in={open}>
          <Box className="hideScrollBar" sx={style}>
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            > */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // marginTop: "10px",
                // borderBottom: "1px solid #eee",
                paddingBottom: "10px",
              }}
            >
              <h3 style={{ color: "#fd5d29" }}>List Details</h3>
              <CancelOutlinedIcon
                onClick={() => handleClose()}
                style={{
                  color: "#fd5d29",
                  fontSize: "30px",
                  cursor: "pointer",
                }}
              />
            </div>
            {/* <span
                style={{ position: "absolute", right: "50px", top: "20px" }}
              > */}

            {/* </span> */}
            {/* </Box> */}
            {loading ? (
              <Box sx={{ height: "400px" }}>
                <Loader />
              </Box>
            ) : (
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                {/* Media Display */}
                <Box sx={{ position: "relative" }}>
                  <ArrowBackIosIcon
                    onClick={() =>
                      setCurrentMedia((prev) =>
                        prev === 0 ? media?.length - 1 : prev - 1
                      )
                    }
                    style={{
                      color: "#fd5d29",
                      position: "absolute",
                      top: "50%",
                      left: "6px",
                      background: "#fff",
                      borderRadius: "50%",
                      paddingLeft: "7px",
                      cursor: "pointer",
                      display: media?.length > 1 ? "block" : "none",
                    }}
                  />
                  {/* {data?.feed_media?.map((item, index) => */}
                  {media?.length > 0 && media[currentMedia]?.type === 1 ? (
                    <img
                      key={currentMedia}
                      className="imageReplace"
                      src={apiList.baseURL + media[currentMedia]?.media}
                      style={{
                        width: "100%",
                        height: "400px",
                        borderRadius: "15px",
                        marginBottom: "10px",
                      }}
                      alt="Media"
                    />
                  ) : media?.length > 0 && media[currentMedia]?.type === 2 ? (
                    <video
                      key={currentMedia}
                      controls
                      className="imageReplace"
                      style={{
                        width: "100%",
                        borderRadius: "15px",
                        marginBottom: "10px",
                        height: "400px",
                      }}
                    >
                      <source
                        src={apiList.baseURL + media[currentMedia]?.media}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  ) : null}
                  <ArrowBackIosIcon
                    onClick={() =>
                      setCurrentMedia((prev) =>
                        prev === media?.length - 1 ? 0 : prev + 1
                      )
                    }
                    style={{
                      color: "#fd5d29",
                      position: "absolute",
                      top: "50%",
                      right: "6px",
                      background: "#fff",
                      borderRadius: "50%",
                      paddingLeft: "7px",
                      transform: `rotate(180deg)`,
                      cursor: "pointer",
                      display: media?.length > 1 ? "block" : "none",
                    }}
                  />
                </Box>

                {/* Post Details */}
                <Box>
                  <Typography variant="h6" gutterBottom component="div">
                    {data?.title}
                  </Typography>
                  <Typography sx={{ color: "#2E2514D1" }}>
                    {data?.description}
                  </Typography>
                </Box>

                {/* Date Details */}
                <Box>
                  {[
                    {
                      title: "Category",
                      value: data?.category_name,
                      isAvailable: data?.category_name ? true : false,
                    },
                    {
                      title: "Sub Category",
                      value: data?.category_sub_name,
                      isAvailable: data?.category_sub_name ? true : false,
                    },
                    {
                      title: "Price",
                      value: "$" + data?.price,
                      isAvailable: data?.price ? true : false,
                    },
                    {
                      title: "Posted Date",
                      value:
                        moment(data?.created_at).format("DD/MM/YYYY") ?? "-",
                      isAvailable: data?.created_at ? true : false,
                    },
                    {
                      title: "Exp. Date",
                      value: data?.expired_at
                        ? moment(data?.expired_at).format("DD/MM/YYYY")
                        : "-",
                      isAvailable: data?.expired_at ? true : false,
                    },
                  ]?.map((x, i) => (
                    <Box
                      sx={{
                        display: x?.isAvailable ? "flex" : "none",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 1,
                        mb: 0.5,
                      }}
                    >
                      <Box sx={{ color: "#fd5d29" }}>{x?.title}:</Box>
                      <Box>{x?.value}</Box>
                    </Box>
                  ))}
                </Box>

                <Box
                  sx={{
                    borderRadius: "12px",
                    background: "#fff2ee",
                    display:
                      location.pathname !== RouteList.userDetails
                        ? "block"
                        : "none",
                  }}
                >
                  {/* User Info */}
                  <Box>
                    <Box
                      sx={{
                        background: "#fd5d29",
                        borderTopRightRadius: "12px",
                        borderTopLeftRadius: "12px",
                        color: "#FFF",
                        p: 0.5,
                        mb: 1,
                        pl: 1,
                      }}
                    >
                      <Typography sx={{ fontSize: "14px" }}>
                        User Info
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 1,
                        px: 1,
                        pb: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <img
                          src={
                            data?.user_detail?.profile_img
                              ? apiList.baseURL + data?.user_detail?.profile_img
                              : defaultUser
                          }
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "12px",
                          }}
                          alt="User"
                        />
                        <Typography sx={{ fontSize: "14px" }}>
                          {data?.user_detail?.username}
                        </Typography>
                      </Box>
                      <Button
                        sx={{
                          width: "100px",
                          textTransform: "none",
                          borderRadius: "12px",
                          fontSize: "14px",
                          color: "#FFF",
                          background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
                          "&:hover": {
                            background: "#00075",
                          },
                          padding: "4px",
                        }}
                        onClick={() =>
                          navigate(RouteList.userDetails, {
                            state: {
                              userId: data?.user_detail?.id,
                            },
                          })
                        }
                      >
                        Details
                      </Button>
                    </Box>
                  </Box>

                  {/* Business Info */}
                </Box>

                <Box
                  sx={{
                    display:
                      data?.user_business?.business_name &&
                        location.pathname !== RouteList.businessDetails
                        ? "block"
                        : "none",
                    borderRadius: "12px",
                    background: "#fff2ee",
                    mt: 2,
                  }}
                >
                  {/* User Info */}
                  <Box>
                    <Box
                      sx={{
                        background: "#fd5d29",
                        borderTopRightRadius: "12px",
                        borderTopLeftRadius: "12px",
                        color: "#FFF",
                        p: 0.5,
                        mb: 1,
                        pl: 1,
                      }}
                    >
                      <Typography sx={{ fontSize: "14px" }}>
                        Business Info
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 1,
                        px: 1,
                        pb: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <img
                          src={
                            apiList.baseURL + data?.user_business?.business_logo
                          }
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "12px",
                          }}
                          alt="User"
                        />
                        <Typography sx={{ fontSize: "14px" }}>
                          {data?.user_business?.business_name}
                        </Typography>
                      </Box>
                      <Button
                        sx={{
                          width: "100px",
                          textTransform: "none",
                          borderRadius: "12px",
                          fontSize: "14px",
                          color: "#FFF",
                          background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
                          "&:hover": {
                            background: "#00075",
                          },
                          padding: "4px",
                        }}
                        onClick={() =>
                          navigate(RouteList.businessDetails, {
                            state: {
                              businessId: data?.user_business?.id,
                            },
                          })
                        }
                      >
                        Details
                      </Button>
                    </Box>
                  </Box>

                  {/* Business Info */}
                </Box>
              </Box>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default PostDetailsModal;
