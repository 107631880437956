import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { RouteList } from "../../Component/RoutesList";
import activeDash from "../../Assets/Image/sidebar/dashboard_active.webp";
import activeCat from "../../Assets/Image/sidebar/Category_active.webp";
import activeUser from "../../Assets/Image/sidebar/user.webp";
import activeAdminRole from "../../Assets/Image/sidebar/adminroles_active.webp";
import activeAnalytics from "../../Assets/Image/sidebar/analytics_active.webp";
import activeBusiness from "../../Assets/Image/sidebar/buisness_active.webp";
import activeComplain from "../../Assets/Image/sidebar/complaint_active.webp";
import activeContent from "../../Assets/Image/sidebar/content_active.webp";
import activeHelp from "../../Assets/Image/sidebar/help_active.webp";
import activeList from "../../Assets/Image/sidebar/list_active.webp";
import activeReport from "../../Assets/Image/sidebar/report_active.webp";
import activeReview from "../../Assets/Image/sidebar/review_active.webp";
import activeSubscription from "../../Assets/Image/sidebar/subscriptions_active.webp";
import user from "../../Assets/Image/sidebar/User_active.webp";
import cat from "../../Assets/Image/sidebar/Category.webp";
import Dash from "../../Assets/Image/sidebar/dashboard.webp";
import subscription from "../../Assets/Image/sidebar/subscriptions.webp";
import review from "../../Assets/Image/sidebar/review.webp";
import report from "../../Assets/Image/sidebar/report.webp";
import list from "../../Assets/Image/sidebar/list.webp";
import help from "../../Assets/Image/sidebar/help.webp";
import content from "../../Assets/Image/sidebar/content.webp";
import complaint from "../../Assets/Image/sidebar/complaint.webp";
import business from "../../Assets/Image/sidebar/business.webp";
import analytics from "../../Assets/Image/sidebar/analytics.webp";
import adminroles from "../../Assets/Image/sidebar/adminroles.webp";
import noti from "../../Assets/Image/sidebar/Bell.png";
import notiA from "../../Assets/Image/sidebar/Bell_light.png";

const SideMenubar = () => {
  const location = useLocation();
  const userRoles =
    JSON.parse(localStorage.getItem("Megalopolist-userData-roles")) || [];
  const SideMenuList = [
    {
      id: 1,
      name: "Dashboard",
      link: RouteList.dashboard,
      // icon: (color) => <DashBoardSVG color={color} />,
      icon: Dash,
      active: activeDash,
    },
    {
      id: 2,
      name: "User",
      link: RouteList.user,
      icon: user,
      active: activeUser,
    },
    {
      id: 3,
      name: "Listing / Advertisement",
      link: RouteList.list,
      icon: list,
      active: activeList,
    },
    {
      id: 4,
      name: "Business",
      link: RouteList.business,
      icon: business,
      active: activeBusiness,
    },
    {
      id: 5,
      name: "Category",
      link: RouteList.category,
      icon: cat,
      active: activeCat,
    },
    {
      id: 6,
      name: "Content",
      link: RouteList.content,
      icon: content,
      active: activeContent,
    },
    {
      id: 7,
      name: "Review",
      link: RouteList.review,
      icon: review,
      active: activeReview,
    },
    {
      id: 8,
      name: "Complaint",
      link: RouteList.Complaint,
      icon: complaint,
      active: activeComplain,
    },
    {
      id: 9,
      name: "Admin Role",
      link: RouteList.adminRole,
      icon: adminroles,
      active: activeAdminRole,
    },
    {
      id: 14,
      name: "Notification",
      link: RouteList?.notification,
      icon: noti,
      active: notiA,
    },
    // {
    //   id: 10,
    //   name: "Subscription & Payment",
    //   link: RouteList.Subscription,
    //   icon: subscription,
    //   active: activeSubscription,
    // },
    {
      id: 11,
      name: "Analytics & Reporting",
      link: RouteList.Analytics,
      icon: analytics,
      active: activeAnalytics,
    },
    {
      id: 12,
      name: "Help & Support",
      link: RouteList.help,
      icon: help,
      active: activeHelp,
    },
    {
      id: 13,
      name: "Report",
      link: RouteList.report,
      icon: report,
      active: activeReport,
    },
  ];

  return (
    <List
      sx={{
        gap: 0.9,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {SideMenuList.filter((menuItem) =>
        userRoles.some(
          (role) => role.id === menuItem.id && role.admin_user_id !== null
        )
      ).map(({ name, link, icon, active }, index) => {
        const isActive = location.pathname === link;
        const iconColor = isActive ? "#FFFFFF" : "#00000099";
        const iconImage = isActive ? active : icon;

        return (
          <Link
            to={link}
            key={index}
            style={{
              textDecoration: "none",
              color: "#000000",
            }}
          >
            <ListItem
              key={name}
              disablePadding
              sx={{
                background: isActive ? "#FF602D" : "transparent",
                color: iconColor,
                "&:hover": {
                  outline: !isActive ? "1px solid #FF602D" : "none",
                  boxSizing: "border-box",
                  width: "100%",
                },
              }}
            >
              <ListItemButton
                sx={{
                  "& .MuiListItemIcon-root": {
                    minWidth: "55px",
                    pl: 1,
                  },
                  "& .MuiTypography-root": {
                    fontWeight: 600,
                  },
                }}
              >
                <ListItemIcon>
                  <img
                    src={iconImage}
                    style={{ height: "30px", width: "30px" }}
                    alt=""
                  />
                </ListItemIcon>
                <ListItemText sx={{ fontWeight: 600 }} primary={name} />
              </ListItemButton>
            </ListItem>
          </Link>
        );
      })}
    </List>
  );
};

export default SideMenubar;
