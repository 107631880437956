import { Box } from "@mui/material";
import React from "react";

const InputErrorComponent = ({ touched, error }) => {
  return (
    <Box
      sx={{
        fontSize: "12px",
        px: 1.5,
        letterSpacing: "0.03rem",
        py: 0.15,
        height: "14px",
        color: "#d32f2f",
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
      }}
    >
      {touched && Boolean(error) && error}
    </Box>
  );
};

export default InputErrorComponent;
