import DataListingComponent from "../../Component/DataListGrid";
import { useEffect, useState } from "react";
import { apiList } from "../../Component/apiList";
import defaultUserImage from "../../Assets/Image/default.webp";
import { AXIOS } from "../../Component/apiHelper";
import { toast } from "react-toastify";

const RecentUserComponent = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      field: "rowid",
      headerName: "Sr.",
      width: 60,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        return cell?.row?.rowid;
      },
    },
    {
      field: "profile_img",
      headerName: "Profile",
      width: 90,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img
            src={
              cell?.row?.profile_img
                ? apiList.baseURL + cell?.row?.profile_img
                : defaultUserImage
            }
            alt="profile"
            style={{ borderRadius: "50%", height: "35px", width: "35px" }}
          />
        </div>
      ),
    },
    {
      field: "username",
      headerName: "User Name",
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 250,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
    },

  ];

  const getData = async () => {
    try
    {
      setLoading(true);

      const res = await AXIOS.get(
        `${apiList.user.getAll}?skip=0&limit=10`
      );
      if (res?.s)
      {
        // const updatedData = count === 0 ? res?.r : [...data, ...res?.r];
        const updatedData = res?.r;
        const serialData = updatedData?.map((x, i) => {
          return { ...x, rowid: i + 1 };
        });
        setData(serialData);
        setLoading(false);
      } else
      {
        toast.warn(res?.m);
        setLoading(false);
      }
    } catch (error)
    {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <DataListingComponent
        data={data}
        loading={loading}
        columns={columns}
        hideFooter={true}
      />
    </>
  );
};

export default RecentUserComponent;
