import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { TextField } from "@mui/material";

const DatePickerComponent = ({ value, setValue, name, placeholder }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        views={["year", "month", "day"]}
        value={value ? dayjs(value) : null}
        name={name}
        sx={{
          color: "#fd5d29",
          border: "1px solid #FD5D29",
          borderRadius: "12px",
          width: "150px",
          backgroundColor: "#FFF6F3",
          ".MuiOutlinedInput-input": {
            padding: "8px",
            color: "#fd5d29",
            fontSize: "15px",
          },
          ".MuiInputLabel-root": {
            textAlign: "start",
            transformOrigin: "start",
            width: "100%",
          },
          ".MuiInputLabel-shrink": {
            textAlign: "left",
          },
          "& .MuiSvgIcon-root": {
            color: "#fd5d29",
          },
          "& .MuiSvgIcon-root:hover": {
            color: "#fd5d29",
          },
          fieldset: {
            border: "none",
          },
        }}
        format="DD/MM/YYYY"
        onChange={(newValue) => {
          setValue(newValue ? newValue.format("YYYY-MM-DD") : "");
        }}
        // label={placeholder}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            fullWidth
            InputLabelProps={{
              shrink: true,
              // paddingTop: 0
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePickerComponent;
