import { Box } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useState } from "react";
import { apiList } from "../../Component/apiList";

const ListMediaComponent = ({ media }) => {
  const [currentMedia, setCurrentMedia] = useState(0);
  return (
    <Box
      sx={{
        // border: "1px solid #febba5",
        borderRadius: "8px",
        // padding: "20px",
        width: {
          xs: "100%",
          md: "50%",
          lg: "325px",
          xl: "400px",
        },
        // margin: "20px 0 0",
        backgroundColor: "#ffffff",
      }}
    >
      <Box>
        <Box sx={{ position: "relative" }}>
          <ArrowBackIosIcon
            onClick={() =>
              setCurrentMedia((prev) =>
                prev === 0 ? media?.length - 1 : prev - 1
              )
            }
            style={{
              color: "#fd5d29",
              position: "absolute",
              top: "50%",
              left: "6px",
              background: "#fff",
              borderRadius: "50%",
              paddingLeft: "7px",
              cursor: "pointer",
              display: media?.length > 1 ? "block" : "none",
            }}
          />

          {media?.length > 0 && media[currentMedia]?.type === 1 ? (
            <img
              key={currentMedia}
              className="imageReplace"
              src={apiList.baseURL + media[currentMedia]?.media}
              style={{
                width: "100%",
                height: "280px",
                borderRadius: "15px",
                // marginBottom: "10px",
              }}
              alt="Media"
            />
          ) : media?.length > 0 && media[currentMedia]?.type === 2 ? (
            <video
              key={currentMedia}
              controls
              className="imageReplace"
              style={{
                width: "100%",
                borderRadius: "15px",
                // marginBottom: "10px",
                height: "280px",
              }}
            >
              <source
                src={apiList.baseURL + media[currentMedia]?.media}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          ) : null}
          <ArrowBackIosIcon
            onClick={() =>
              setCurrentMedia((prev) =>
                prev === media?.length - 1 ? 0 : prev + 1
              )
            }
            style={{
              color: "#fd5d29",
              position: "absolute",
              top: "50%",
              right: "6px",
              background: "#fff",
              borderRadius: "50%",
              paddingLeft: "7px",
              transform: `rotate(180deg)`,
              cursor: "pointer",
              display: media?.length > 1 ? "block" : "none",
            }}
          />
        </Box>
        <Box
          sx={{
            display: media?.length > 1 ? "flex" : "none",
            justifyContent: "start",
            alignItems: "center",
            gap: 1,
          }}
        >
          {media?.map((x, i) => (
            <Box
              sx={{
                height: "50px",
                width: "60px",
                border:
                  media[currentMedia]?.media === x?.media
                    ? "2px dashed grey"
                    : "none",
                borderRadius: "15px",
                cursor: "pointer",
                margin: "10px 0",
              }}
              onClick={() => setCurrentMedia(i)}
            >
              {x?.type === 1 ? (
                <img
                  key={i}
                  className="imageReplace"
                  src={apiList.baseURL + x?.media}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "15px",
                  }}
                  alt="Media"
                />
              ) : x?.type === 2 ? (
                <video
                  key={i}
                  controls
                  className="imageReplace"
                  style={{
                    width: "100%",
                    borderRadius: "15px",
                    height: "100%",
                  }}
                >
                  <source src={apiList.baseURL + x?.media} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : null}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ListMediaComponent;
