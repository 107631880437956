export const StatusList = [
  {
    code: -4,
    name: "Banned",
    color: "#ff0000",
    message: "Are you sure you want to ban this user account?",
    toast: "Banned Successfully",
  },
  {
    code: -3,
    name: "Suspended",
    color: "#ff8000",
    message: "Are you sure you want to suspend this user account?",
    toast: "Suspended Successfully",
  },
  {
    code: -2,
    name: "Flagged",
    color: "#ffa500",
    message: "Are you sure you want to flag this user account?",
    toast: "Flagged Successfully",
  },
  {
    code: -1,
    name: "Deleted",
    color: "#808080",
    message:
      "Are you sure you want to delete this user account? If you press Confirm, this account will be permanently deleted.",
    toast: "Deleted Successfully",
  },
  {
    code: 0,
    name: "Pending",
    color: "#8080ff",
  },
  {
    code: 1,
    name: "Verified",
    color: "#008000",
    message: "Are you sure you want to Re-active this user account?",
    toast: "Re-activated Successfully",
  },
];
