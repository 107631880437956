import axios from "axios";
import { apiList } from "./apiList";

const getUserData = () => {
  try
  {
    const userData = localStorage.getItem("Megalopolist-userData");
    return userData ? JSON.parse(userData) : null;
  } catch (error)
  {
    return null;
  }
};

export const AXIOS = (() => {
  const { baseURL } = apiList;

  const axiosInstance = axios.create({
    baseURL,
  });

  axiosInstance.interceptors.request.use((config) => {
    const userData = getUserData();
    if (userData)
    {
      config.headers.apikey = userData?.auth_details?.apikey || "";
      config.headers.token = userData?.auth_details?.token || "";
    }
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      if (response.status === 200)
      {
        return response.data;
      }
    },
    (error) => {
      console.log(error);
      // if (error.response && error.response.status === 401) {
      //   window.location.href = "/login";
      //   localStorage.clear();
      // }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
})();
