import { Box, CircularProgress } from "@mui/material";
import React from "react";
import ReactQuill from "react-quill";

const QuillEditerComponent = ({ formik, ref, loading }) => {
  return (
    <>
      <Box
        sx={{
          color: "#FFF",
          width: "100%",
          textAlign: "left",
          borderRadius: "10px",
          boxSizing: "border-box",
          p: 2,
          // background: `#FD5D29`,
          border: "1px solid #00000033",
          boxShadow: "0px 0px 3px 0px #00000020",

          "& .ql-container": {
            height: "calc(100vh - 280px)",
            overflow: "auto",
            resize: "both",
            background: "#FFF",
            color: "#000",
            // borderBottomLeftRadius: "12px",
            // borderBottomRightRadius: "12px",
            border: "none",
          },
          "& .ql-editor": {
            py: 1,
            px: 0.5,
          },
          "& .ql-toolbar": {
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            // borderTopLeftRadius: "10px",
            // borderTopRightRadius: "10px",
            borderRadius: "8px",
            zIndex: 999,
            background: `#FD5D29`,
          },
          "& .ql-toolbar .ql-picker": {
            color: "#FFF",
          },
          "& .ql-toolbar button": {
            color: "#FFF !important",
          },
          "& .ql-toolbar .ql-stroke": {
            stroke: "#FFF",
          },
          "& .ql-toolbar .ql-fill": {
            color: "#FFF !important",
          },
          "& .ql-toolbar .ql-picker-options": {
            backgroundColor: "#333",
            color: "#FFF",
          },
          "& .ql-toolbar .ql-picker-label": {
            color: "#FFF !important",
          },
          "& .ql-toolbar button:focus": {
            backgroundColor: "#fd5d2950",
            color: "#FFF",
          },
          ".ql-editor.ql-blank::before": {
            color: "#FFF75",
            fontWeight: "500",
          },
        }}
      >
        {loading ? (
          <Box
            className="flexCenter"
            sx={{
              height: "calc(100vh - 238px)",
            }}
          >
            <CircularProgress style={{ color: "#fd5d29" }} />
          </Box>
        ) : (
          <ReactQuill
            theme="snow"
            placeholder="Content"
            value={formik.values.content}
            ref={ref}
            onChange={(content) => {
              content = content.replace(/(^<[^>]*>)([\s]*)/gm, "$1");
              formik.setFieldValue("content", content);
            }}
            modules={{
              toolbar: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ["bold", "italic", "underline", "blockquote"],
                [
                  { list: "ordered" },
                  { list: "bullet" },
                  { indent: "-1" },
                  { indent: "+1" },
                ],
                ["link"],
                ["clean"],
                [
                  { align: "right" },
                  { align: "center" },
                  { align: "justify" },
                  { align: "" },
                ],
                [
                  {
                    color: [
                      "black",
                      "#D8D4FF",
                      "gray",
                      "silver",
                      "maroon",
                      "red",
                      "purple",
                      "green",
                      "lime",
                      "yellow",
                      "navy",
                      "blue",
                      "teal",
                      "aqua",
                    ],
                  },
                  {
                    background: [
                      "transparent",
                      "#f5f5f5", // Light Grey
                      "#dcdcdc", // Gainsboro
                      "#c0c0c0", // Silver
                      "#a9a9a9", // Dark Grey
                      "#808080", // Grey
                      "#696969", // Dim Grey
                      "#595959", // Dark Grey
                      "#404040", // Black
                      "#2f2f2f", // Dark Grey
                      "#202020", // Dark Grey
                      "#101010", // Black
                      "#ff0000", // Red
                      "#00ff00", // Green
                      "#0000ff", // Blue
                      "#ffff00", // Yellow
                      "#ff00ff", // Magenta
                      "#00ffff", // Cyan
                      "#808080", // Gray
                      "#800080", // Purple
                      "#008000", // Green
                      "#000080", // Navy
                      "#808000", // Olive
                      "#ffa500", // Orange
                      "#ff69b4", // Pink
                      "#ffdab9", // Peach
                      "#fa8072", // Salmon
                      "#a52a2a", // Brown
                      "#008080", // Teal
                      "#00ffff", // Cyan
                      "#90ee90", // Light Green
                      "#ffb6c1", // Pink
                      "#fffaf0", // Floral White
                      "#ffffe0", // Light Yellow
                    ],
                  },
                ],
              ],
            }}
          />
        )}
        {formik.errors.content && formik.touched.content ? (
          <div>{formik.errors.content}</div>
        ) : null}
      </Box>
    </>
  );
};

export default QuillEditerComponent;
