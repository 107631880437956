import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import SubscriptionDataTab from "./SubscriptionDataTab";
import PaymentDataTab from "./PaymentDataTab";

const PaymentScreen = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const handleTabChange = (tab) => {
    setCurrentTab(tab);
  };
  return (
    <>
      <Box className="flexBetween">
        <Typography>
          <h2> Subscription & Payment Management </h2>
        </Typography>
      </Box>
      <Box
        sx={{
          py: 2,
          display: "flex",
          gap: 2,
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            color: currentTab === 0 ? "#FD5D29" : "grey",
            fontWeight: currentTab === 0 ? "600" : "400",
            // textDecoration: currentTab === 0 ? "#FD5D2945" : "#FFF",
            textDecoration: currentTab === 0 ? "underline" : "none",
            // py: 0.5,
            borderRadius: "12px",
            cursor: "pointer",
          }}
          onClick={() => handleTabChange(0)}
        >
          Subscription
        </Box>
        <Box
          sx={{
            color: currentTab === 1 ? "#FD5D29" : "grey",
            fontWeight: currentTab === 1 ? "600" : "400",
            textDecoration: currentTab === 1 ? "underline" : "none",
            // py: 0.5,
            borderRadius: "12px",
            cursor: "pointer",
          }}
          onClick={() => handleTabChange(1)}
        >
          Payment
        </Box>
      </Box>
      <Box sx={{ height: "calc(100vh - 230px)", }}>
        {currentTab === 0 ? <SubscriptionDataTab /> : <PaymentDataTab />}
      </Box>
    </>
  );
};

export default PaymentScreen;
