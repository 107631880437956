import { Box, Button, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AXIOS } from "../../Component/apiHelper";
import { apiList } from "../../Component/apiList";
import { toast } from "react-toastify";
import TReview from "../../Assets/Image/userDetails/Group 8-1.png";
// import TCoSu from "../../Assets/Image/userDetails/Group 8-2.png";
// import TCoRe from "../../Assets/Image/userDetails/Group 8-3.png";
import back from "../../Assets/Image/back.png";
import Loader from "../../Component/Loader";
import UserDetailsSidebar from "../../Component/UserDetailsSidebar";
import DocumentVerification from "./DocumnetSection";
import BasicInformation from "./BasicDetailSection";
import BusinessHours from "./businessHours";
import { RouteList } from "../../Component/RoutesList";
import BusinessCategory from "./BusinessCategry";
import ConfirmationModal1 from "../../Component/ConfirmModal1";
import { StatusList } from "../../Component/StatusList";
import IndividualNotificationComponent from "../Notification/IndividualNotificationModal";

const BusinessDetailsScreen = () => {
  const location = useLocation();
  const businessId = location?.state?.businessId;
  const nav = useNavigate();
  const [data, setData] = useState();
  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(false);
  const [actionParams, setActionParams] = useState({
    userId: null,
    status: null,
    is_approve: null,
  });

  const getDetailsById = async (id) => {
    try
    {
      setloading(true);
      const res = await AXIOS.get(
        `${apiList.business.getDetailById}?user_business_id=${id}`
      );
      if (res?.s)
      {
        setData(res?.r);
      } else
      {
        toast.error(res?.m);
      }
      setloading(false);
    } catch (error)
    {
      setloading(false);
      console.log(error);
    }
  };

  const handleBusinessAction = async (id, z, status) => {
    const f = new FormData();
    f.append("user_business_id", id);
    f.append("is_approve", status);
    try
    {
      const res = await AXIOS.post(`${apiList.business.updateBusiness}`, f);
      if (res?.s)
      {
        status === 1
          ? toast.success("Approved successfully")
          : toast.error("Rejected successfully");
        getDetailsById(businessId);
      } else
      {
        toast.error(res?.m);
      }
    } catch (error)
    {
      console.log(error);
    }
  };

  useEffect(() => {
    businessId && getDetailsById(businessId);
  }, [businessId]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        justifyContent: "start",
        alignItems: "start",
      }}
    >
      {/* Side bar code */}
      {loading ? (
        <Box sx={{ height: "100%", width: "100%" }}>
          <Loader />
        </Box>
      ) : (
        <>
          <UserDetailsSidebar
            data={data?.user_detail}
            getData={() => getDetailsById(businessId)}
          />

          {/* user Details code */}
          <Box
            sx={{
              p: 3,
              height: "100%",
              width: { xs: "100%", md: "calc(100% - 260px)" },
              boxSizing: "border-box",
              overflow: "scroll",
            }}
          >
            <Toolbar sx={{ display: { xs: "block", md: "none" } }} />
            <Box className="flexBetween" sx={{ flexWrap: "wrap" }}>
              <Typography>
                <h2
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <img
                    src={back}
                    style={{ height: "30px", width: "30px", cursor: "pointer" }}
                    onClick={() => nav(RouteList.business)}
                    alt=""
                  />{" "}
                  Business Details{" "}
                </h2>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  disabled={data?.is_approve === 1}
                  sx={{
                    borderRadius: "12px",
                    background: data?.is_approve === 0 ? "#009b00" : "#FFF",
                    border: data?.is_approve === 1 && "1px solid #009b00",
                    textTransform: "none",
                    display:
                      data?.is_approve === 0 || data?.is_approve === 1
                        ? "flex"
                        : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                    color: data?.is_approve === 1 ? "#009b00" : "#FFF",
                    width: "100%",
                    padding: "5px 20px",
                    "&.Mui-disabled": {
                      color: "#009b00",
                    },
                  }}
                  onClick={() => {
                    setOpen(true);
                    setActionParams({
                      userId: data?.id,
                      is_approve: 1,
                    });
                  }}
                >
                  {data?.is_approve === 0
                    ? "Approve"
                    : data?.is_approve === 1 && "Approved"}
                </Button>
                <Button
                  fullWidth
                  disabled={data?.is_approve === -1}
                  variant="contained"
                  sx={{
                    borderRadius: "12px",
                    background: data?.is_approve === 0 ? "red" : "#FFF",
                    textTransform: "none",
                    border: data?.is_approve === -1 && "1px solid red",
                    display:
                      data?.is_approve === 0 || data?.is_approve === -1
                        ? "flex"
                        : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                    color: data?.is_approve === -1 ? "red" : "#FFF",
                    width: "100%",
                    padding: "5px 20px",
                    "&.Mui-disabled": {
                      color: "red",
                    },
                  }}
                  onClick={() => {
                    setOpen(true);
                    setActionParams({
                      userId: data?.id,
                      is_approve: -1,
                    });
                  }}
                >
                  {data?.is_approve === 0
                    ? "Reject"
                    : data?.is_approve === -1 && "Rejected"}
                </Button>
                <IndividualNotificationComponent
                  type={2}
                  userId={data?.user_id}
                />
              </Box>
            </Box>

            <Box sx={{ height: "calc(100vh - 120px)" }}>
              {loading ? (
                <Box sx={{ height: "100%", width: "100%" }}>
                  <Loader />
                </Box>
              ) : (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      width: "100%",
                      flexWrap: "wrap",
                    }}
                  >
                    <BasicInformation
                      data={data}
                      getData={() => getDetailsById(businessId)}
                    />
                    <Box>
                      {data?.user_identity?.id && (
                        <DocumentVerification
                          data={{
                            ...data?.user_identity,
                            businessId: businessId,
                          }}
                          getData={() => getDetailsById(businessId)}
                        />
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: { md: 2 },
                      flexDirection: { xs: "column", md: "row" },
                    }}
                  >
                    {/* {data?.user_business_hour?.length > 0 && ( */}
                    <BusinessHours
                      data={data?.user_business_hour}
                      businessId={businessId}
                      getData={() => getDetailsById(businessId)}
                      edit={true}
                    />
                    {/* )} */}
                    {data?.user_business_category?.id && (
                      <BusinessCategory
                        data={{
                          ...data?.user_business_category,
                          businessId: businessId,
                        }}
                        getData={() => getDetailsById(businessId)}
                      />
                    )}
                  </Box>
                </Box>
              )}
              <ConfirmationModal1
                open={open}
                setOpen={setOpen}
                onConfirm={() =>
                  handleBusinessAction(
                    actionParams.userId,
                    actionParams.status,
                    actionParams.is_approve
                  )
                }
                title="Confirmation"
                message={`Are you sure to ${actionParams?.is_approve === 1 ? "approve" : "reject"
                  }?`}
                confirmText="Confirm"
                cancelText="Cancel"
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default BusinessDetailsScreen;
