import { useEffect, useRef, useState } from "react";
import { apiList } from "../../Component/apiList";
import { AXIOS } from "../../Component/apiHelper";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import zIndex from "@mui/material/styles/zIndex";

const BusinessCategory = ({ data, getData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mainCategory, setMainCategory] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [loading, setloading] = useState(false);
  const mainIdRef = useRef();
  mainIdRef.current = data?.id;

  const getChildCategoryList = async (id) => {
    try
    {
      let res = await AXIOS.get(
        `${apiList.category.subGetById}?select=*&from=category_sub&arg=category_id = ? and status = ?&prms=[${id},1]`
      );
      if (res?.s)
      {
        setSubCategoryList(res?.r);
      }
    } catch (error)
    {
      console.log(error);
    }
  };

  const styles = {
    container: {
      border: "1px solid #febba5",
      borderRadius: "8px",
      padding: "20px",
      maxWidth: "800px",
      margin: "20px 0",
      minWidth: "400px",
      backgroundColor: "#ffffff",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid #febba550",
      paddingBottom: "12px",
    },
    title: {
      fontSize: "18px",
      fontWeight: "600",
      color: "#fd5d29",
    },
    editButton: {
      padding: "8px 16px",
      backgroundColor: "#Fd5d29",
      color: "white",
      border: "none",
      borderRadius: "8px",
      cursor: "pointer",
    },
    dayRow: {
      display: "flex",
      justifyContent: "space-between",
      padding: "12px",
      borderBottom: "1px solid #f0f0f0",
      alignItems: "center",
    },
    categoryTitle: {
      fontWeight: "500",
      color: "#444",
      width: "120px",
    },

    modalOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    },
    modal: {
      backgroundColor: "white",
      padding: "24px",
      borderRadius: "8px",
      width: "90%",
      maxWidth: "500px",
      maxHeight: "90vh",
      overflow: "auto",
      zIndex: 10001,
    },
    modalHeader: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "10px",
      borderBottom: "1px solid #eee",
      paddingBottom: "10px",
      color: "#fd5d29",
    },

    buttonGroup: {
      display: "flex",
      justifyContent: "flex-end",
      gap: "12px",
      marginTop: "18px",
      paddingTop: "18px",
    },
    saveButton: {
      padding: "8px 16px",
      backgroundColor: "#28a745",
      color: "white",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
    cancelButton: {
      padding: "8px 16px",
      backgroundColor: "#dc3545",
      color: "white",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
  };

  const getAllCategoryList = async () => {
    try
    {
      let res = await AXIOS.get(
        `${apiList.category.getAll}?select=*&from=category&arg=status = 1`
      );
      if (res?.s)
      {
        setMainCategory(res?.r);
        let current = res?.r?.find((x) => x?.id === data?.id);
        if (current)
        {
          formik.setFieldValue("mainCategory", current);
        }
        getChildCategoryList(data?.id);
      }
    } catch (error)
    {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      mainCategory: data,
      subCategories: data?.user_business_category_sub,
    },
    validationSchema: Yup.object({
      mainCategory: Yup.object().required("Main category is required"),
      subCategories: Yup.array()
        .min(1, "At least one sub-category is required")
        .required("Sub-categories are required"),
    }),
    onSubmit: async (values) => {
      try
      {
        setloading(true);
        const subCatIds = values.subCategories.map((x) => x?.id);

        const fd = new FormData();
        fd.append("user_business_id", data?.businessId);
        if (mainIdRef.current !== values.mainCategory.id)
          fd.append("category_id", values.mainCategory.id);
        fd.append("category_sub_id", subCatIds);
        const res = await AXIOS.post(apiList.business.updateBusiness, fd);
        if (res?.s)
        {
          toast.success(res?.m);
          getData();
        }
        setloading(false);
        setIsModalOpen(false);
      } catch (error)
      {
        setIsModalOpen(false);
        console.error(error);
        setloading(false);
      }
    },
  });

  useEffect(() => {
    if (data)
    {
      getAllCategoryList();
    }
  }, [data]);

  useEffect(() => {
    if (formik.values.mainCategory)
    {
      if (mainIdRef.current !== formik.values.mainCategory?.id)
        formik.setFieldValue("subCategories", []);
      getChildCategoryList(formik.values.mainCategory?.id);
    }
  }, [formik.values.mainCategory]);

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h2 style={styles.title}>Business Category</h2>

        <Button
          onClick={() => {
            setIsModalOpen(true);
            formik.setFieldValue({
              mainCategory: data,
              subCategories: data?.user_business_category_sub,
            });
          }}
          variant="contained"
          sx={{

            textTransform: "none",
            width: "70px",
            borderRadius: "9px",
            background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
          }}
        >
          Edit
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          gap: 5,
          alignItems: "center",
          // borderBottom: "1px solid #f0f0f0",
          padding: "12px 0",
        }}
      >
        <img
          src={apiList.baseURL + data?.image}
          style={{ height: "20px", width: "20px" }}
          alt=""
        />
        <h4 style={styles.categoryTitle}>{data?.category_name}</h4>
      </div>

      <div
        style={{
          display: "flex",
          gap: "10px",
          // marginTop: "10px",
          alignItems: "center",
          justifyContent: "start",
          width: "100%",
          flexWrap: "wrap",
          maxHeight: "270px",
          overflow: "auto",
        }}
      >
        {data &&
          data?.user_business_category_sub?.map((x, i) => (
            <div
              key={"sub-" + i}
              style={{
                backgroundColor: "#f0f0f0",
                borderRadius: "8px",
                padding: "8px",
              }}
            >
              {x?.category_sub_name}
            </div>
          ))}
      </div>

      {isModalOpen && (
        <div style={styles.modalOverlay} onClick={() => setIsModalOpen(false)}>
          <div style={styles.modal} onClick={(e) => e.stopPropagation()}>
            <div style={styles.modalHeader}>
              <h3 style={{ color: "#fd5d29" }}>Edit Business category</h3>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mt: 1 }}>
                <label>Main Category</label>
                <Autocomplete
                  options={mainCategory}
                  getOptionLabel={(option) => option.category_name}
                  clearIcon={false}
                  value={formik.values.mainCategory}
                  onChange={(e, newValue) =>
                    formik.setFieldValue("mainCategory", newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      error={Boolean(
                        formik.errors.mainCategory &&
                        formik.touched.mainCategory
                      )}
                      sx={{
                        margin: "0.25rem 0 0 0",
                        ".MuiInputBase-root": {
                          borderRadius: "15px",
                          background: "#FD5D2914",
                          color: "#000",
                          border: "1px solid #FD5D2966",
                        },
                        ".MuiOutlinedInput-input": {
                          padding: "14px",
                        },
                        // ".MuiInputBase-root:hover": {
                        //   background: "#FF8862",
                        //   color: "#fff",
                        // },

                        ".MuiOutlinedInput-input:focus": {
                          outline: "none",
                        },
                        fieldset: {
                          border: "none",
                        },
                      }}
                      helperText={
                        Boolean(
                          formik.errors.mainCategory &&
                          formik.touched.mainCategory
                        ) && formik.errors.mainCategory
                      }
                    />
                  )}
                />
              </Box>

              <Box sx={{ mt: 2 }}>
                <label>Sub Categories</label>
                <Autocomplete
                  multiple
                  options={subCategoryList}
                  getOptionLabel={(option) => option.category_sub_name}
                  value={formik.values.subCategories}
                  onChange={(e, newValue) =>
                    formik.setFieldValue("subCategories", newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      error={Boolean(
                        formik.errors.subCategories &&
                        formik.touched.subCategories
                      )}
                      sx={{
                        margin: "0.25rem 0 0 0",
                        ".MuiInputBase-root": {
                          borderRadius: "15px",
                          background: "#FD5D2914",
                          color: "#000",
                          border: "1px solid #FD5D2966",
                        },
                        ".MuiOutlinedInput-input": {
                          padding: "14px",
                        },
                        // ".MuiInputBase-root:hover": {
                        //   background: "#FF8862",
                        //   color: "#fff",
                        // },

                        ".MuiOutlinedInput-input:focus": {
                          outline: "none",
                        },
                        fieldset: {
                          border: "none",
                        },
                      }}
                      helperText={
                        Boolean(
                          formik.errors.subCategories &&
                          formik.touched.subCategories
                        ) && formik.errors.subCategories
                      }
                    />
                  )}
                />
              </Box>

              <div style={styles.buttonGroup}>
                <Button
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    borderRadius: "15px",
                    border: "1px solid #FF602D",
                    // background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
                    color: "#FF602D",
                    "&:hover": {
                      background: "#00075",
                    },
                  }}
                  onClick={() => {
                    setIsModalOpen(false);
                    formik.resetForm();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={formik.handleSubmit}
                  disabled={loading}
                  sx={{
                    width: "100%",
                    maxWidth: "300px",
                    textTransform: "none",
                    borderRadius: "15px",
                    background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
                    "&:hover": {
                      background: "#00075",
                    },
                  }}
                >
                  {loading ? <CircularProgress size={28} /> : "Update"}
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default BusinessCategory;
