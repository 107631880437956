import { Box, Button, Typography } from "@mui/material";
import DataListingComponent from "../../Component/DataListGrid";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { apiList } from "../../Component/apiList";
import { AXIOS } from "../../Component/apiHelper";

import defaultImage from "../../Assets/Image/logo.webp";
import child from "../../Assets/Image/child.png";
import edit from "../../Assets/Image/edit.webp";
import deleteCat from "../../Assets/Image/delete.webp";
import CategoryModal from "./CategoryManageModal";
import SUbCategoryScreen from "./SubCategoryManagement";
import ConfirmationModal1 from "../../Component/ConfirmModal1";

const CategoryScreen = () => {
  const PAGE_SIZE_OPTIONS = [15];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalData, setTotalData] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [modalType, setModalType] = useState("");
  const [parentId, setParentId] = useState(0);
  const [parentLable, setParentLable] = useState("");
  const [actionParams, setActionParams] = useState(0);

  const columns = [
    {
      field: "rowid",
      headerName: "Sr.",
      width: 60,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      renderCell: ({ row }) => (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            textOverflow: "ellipsis",
          }}
        >
          {row?.rowid + page * rowsPerPage}
        </div>
      ),
    },
    {
      field: "image",
      headerName: "Image",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (cell) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img
            src={
              cell?.row?.image
                ? apiList.baseURL + cell?.row?.image
                : defaultImage
            }
            alt="cate_image"
            style={{ height: "35px", width: "35px" }}
          />
        </div>
      ),
    },
    {
      field: "category_name",
      headerName: "Main Category ",
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      flex: 1,
      display: "flex",
      renderCell: ({ row }) => (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            textOverflow: "ellipsis",
          }}
        >
          {row?.category_name}
        </div>
      ),
    },

    {
      field: "status",
      headerName: "Action",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (cell) => {
        return (
          <Box className="flexCenter" sx={{ height: "100%" }}>
            <Box className="flexCenter" sx={{ height: "100%", gap: 1.5 }}>
              <img
                src={edit}
                alt="Block"
                style={{ height: "25px", width: "25px" }}
                onClick={() => {
                  setModalData(cell?.row);
                  setModalType("update-main");
                }}
              />
              <img
                src={deleteCat}
                style={{ height: "25px", width: "25px" }}
                alt="delete"
                onClick={() => {
                  setOpen(true);
                  setActionParams(cell?.row?.id);
                }}
              />
              <img
                src={child}
                style={{ height: "25px", width: "25px" }}
                alt="child"
                onClick={() => {
                  setParentId(cell?.row?.id);
                  setParentLable(cell?.row?.category_name);
                }}
              />
            </Box>
          </Box>
        );
      },
    },
  ];

  const getData = async (
    page = 0,
    rowsPerPage = PAGE_SIZE_OPTIONS[0],
    search,
    prevSearch
  ) => {
    try {
      const count = page * rowsPerPage;
      setLoading(true);
      if (search !== prevSearch) {
        setData([]);
        setPage(0);
      }
      let searchParams =
        search?.length > 0
          ? `status = 1 and category_name like '%25${search?.trim()}%25'`
          : `status =1`;
      const res = await AXIOS.get(
        `${apiList.category.getAll}?select=*&from=category&arg= ${searchParams}&order=created_at desc&skip=${count}&limit=${rowsPerPage}&total=1`
      );
      if (res?.s) {
        const updatedData = res?.r;
        const serialData = updatedData?.map((x, i) => {
          return { ...x, rowid: i + 1 };
        });
        setData(serialData);
        page === 0 && setTotalData(res?.total ?? 0);
        setLoading(false);
      } else {
        toast.warn(res?.m);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleDeleteCategory = async (id) => {
    try {
      setLoading(true);
      const res = await AXIOS.get(`${apiList.category.deleteMainCat}${id}`);
      if (res?.s) {
        toast.error("Deleted successfully");
        await getData(
          page,
          rowsPerPage,
          debouncedSearchTerm,
          searchRef.current
        );
        setLoading(false);
      } else {
        toast.error(res?.m);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const searchRef = useRef(debouncedSearchTerm);

  useEffect(() => {
    getData(page, rowsPerPage, debouncedSearchTerm, searchRef.current);
    searchRef.current = debouncedSearchTerm;
  }, [debouncedSearchTerm, rowsPerPage, page]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newPageSize) => {
    setRowsPerPage(newPageSize);
    setPage(0);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      {/* <button onClick={handleUpload} disabled={isUploaded} style={{padding:15}}>
        {isUploaded ? "Upload Complete" : d[0]?.name}
      </button> */}
      <Box className="flexBetween">
        <Typography>
          <h2> Category Management </h2>
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "50%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>
              <h4>Category </h4>
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{}}>
                <Button
                  sx={{
                    width: "150px",
                    textTransform: "none",
                    borderRadius: "15px",
                    fontSize: "17px",
                    color: "#FFF",
                    background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
                    "&:hover": {
                      background: "#00075",
                    },
                    padding: "8px",
                  }}
                  onClick={() => {
                    setModalData("0");
                    setModalType("add-main");
                  }}
                >
                  Add
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <input
              type="search"
              name="search"
              value={searchTerm?.trimStart()}
              placeholder="Search by name"
              style={{
                padding: "13px",
                height: "100%",
                borderRadius: "12px",
                border: "1px solid #FD5D29",
                backgroundColor: "#FFF6F3",
                color: "#FD5D29",
                width: "100%",
              }}
              onChange={(e) => setSearchTerm(e.target.value?.trimStart())}
              id="search"
            />
          </Box>
          <Box sx={{ height: "calc(100vh - 230px)" }}>
            <DataListingComponent
              data={data}
              PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS}
              loading={loading}
              totalData={totalData}
              page={page}
              columns={columns}
              rowsPerPage={rowsPerPage}
              handlePageChange={handlePageChange}
              handleRowsPerPageChange={handleRowsPerPageChange}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "50%",
          }}
        >
          <SUbCategoryScreen id={parentId} lable={parentLable} />
        </Box>
      </Box>
      <CategoryModal
        data={modalData}
        open={modalData}
        setOpen={setModalData}
        type={modalType}
        getData={() =>
          getData(
            page,
            rowsPerPage,
            // filter,
            // filterRef.current,
            debouncedSearchTerm,
            searchRef.current
          )
        }
      />
      <ConfirmationModal1
        open={open}
        setOpen={setOpen}
        onConfirm={() => handleDeleteCategory(actionParams)}
        title="Confirm Action"
        message="Are you sure want to delete category?"
        confirmText="Confirm"
        cancelText="Cancel"
        loading={loading}
      />
    </Box>
  );
};

export default CategoryScreen;
