import { Box, Typography } from "@mui/material";
import { useState } from "react";
import ListReportTab from "./ListReportTba";
import AdvReportTab from "./AdvReportTab";
import UserReportTab from "./userReportTab";
// import ListDataScreenTab from "./ListDataScreenTab";
// import AdvDataScreenTab from "./AdvDataScreenTab";
const ReportedUserManagementScreen = () => {
  const [currentTab, setCurrentTab] = useState(0);

  let listData = [
    {
      id: 0,
      lable: "User",
      tab: <UserReportTab />,
    },
    {
      id: 1,
      lable: "List",
      tab: <ListReportTab />,
    },
    {
      id: 2,
      lable: "Advertisement",
      tab: <AdvReportTab />,
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box className="flexBetween">
        <Typography>
          <h2> Report Management </h2>
        </Typography>
      </Box>

      {/* OPEN WHEN USE FILTER */}

      {/* <Box
        sx={{
          height: "50px",
          display: { xs: "flex", lg: "none" },
          // border: "1px solid red",
        }}
      ></Box> */}

      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            pb: 1,
            justifyContent: "space-between",
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              justifyContent: "space-between",
            }}
          >
            {listData?.map((x, i) => (
              <Box
                sx={{
                  color: currentTab === x?.id ? "#fd5d29" : "#00000099",
                  textDecoration: currentTab === x?.id ? "underline" : "none",
                  cursor: "pointer",
                }}
                onClick={() => setCurrentTab(x?.id)}
              >
                {x?.lable}
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            position: "relative",
          }}
        >
          {listData[currentTab]?.tab}
        </Box>
      </Box>
    </Box>
  );
};

export default ReportedUserManagementScreen;
