import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { RouteList } from "../../Component/RoutesList";
import { AXIOS } from "../../Component/apiHelper";
import { apiList } from "../../Component/apiList";
import { toast } from "react-toastify";
import InputErrorComponent from "../../Component/InputErrorComponent";

const LoginScreen = ({ setCurrentScreen }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setloading] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .matches(emailRegex, "Invalid email")
        .required("Required"),
      password: yup
        .string()
        .required("Required")
        .min(6, "Required at least 6 digits.")
        .max(20, "Maximum 20 digits allowed"),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      try
      {
        setSubmitting(true);
        setloading(true);
        const res = await AXIOS.post(apiList.auth.login, values);
        if (res?.s && res.r.status === 1)
        {
          localStorage.setItem("Megalopolist-userData", JSON.stringify(res?.r));
          localStorage.removeItem("currentScreen");
          const ans = await getAccessList(res.r.id);
          navigate(RouteList.dashboard);
          toast.success("Login successfully");
          setloading(false);
        } else if (res?.s && res.r.status === -1)
        {
          toast.error("You are not Authenticated");
        } else
        {
          if (res?.status === 401)
          {
          }
          toast.error(res?.m);
          setloading(false);
        }
        // setSubmitting(false);
        setloading(false);
      } catch (error)
      {
        console.log(error);
      }
    },
  });
  const getAccessList = async (userId) => {
    try
    {
      setloading(true);
      let res = await AXIOS.get(apiList?.subAdmin?.accessbyuserID, {
        params: { user_id: userId },
      });
      if (res?.r)
      {
        localStorage.setItem(
          "Megalopolist-userData-roles",
          JSON.stringify(res?.r)
        );
      }
    } catch (error)
    {
      console.log(error);
      setloading(false);
    }
  };
  return (
    <>
      <h2 style={{ color: "#000", paddingBottom: "0rem" }}>Welcome back!</h2>
      <p style={{ color: "#000000B2", paddingBottom: "1rem" }}>
        Access your admin tools and manage with ease.
      </p>
      <Box sx={{ width: { md: "450px" } }}>
        <label htmlFor="email" style={{ fontSize: "14px", paddingLeft: "0px" }}>
          Email
        </label>
        <TextField
          type="email"
          id="email"
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values?.email?.trim()}
          error={formik.touched.email && Boolean(formik.errors.email)}
          // helperText={formik.touched.email && formik.errors.email}
          variant="outlined"
          fullWidth
          focused
          sx={{
            margin: "0.25rem 0 0 0",
            ".MuiInputBase-root": {
              borderRadius: "15px",
              background: "#FD5D2914",
              color: "#000",
              border: "1px solid #FD5D2966",
            },
            ".MuiOutlinedInput-input": {
              padding: "14px",
            },
            ".MuiInputBase-root:hover": {
              background: "#FF8862",
              color: "#fff",
            },
            "& input[type='password']::-ms-reveal": {
              display: "none",
            },
            "& input[type='password']::-ms-clear": {
              display: "none",
            },

            ".MuiOutlinedInput-input:focus": {
              outline: "none",
            },
            fieldset: {
              border: "none",
            },
          }}
          placeholder="Enter your email"
        />
        <InputErrorComponent
          touched={formik.touched.email}
          error={formik.errors.email}
        />

        <Box>
          <label
            htmlFor="password"
            style={{ fontSize: "14px", paddingLeft: "0px" }}
          >
            Password
          </label>
          <FormControl
            variant="outlined"
            fullWidth
            error={formik.touched.password && Boolean(formik.errors.password)}
            sx={{
              margin: "0.25rem 0 0 0",
              ".MuiInputBase-root": {
                borderRadius: "15px",
                background: "#FD5D2914",
                color: "#000",
                border: "1px solid #FD5D2966",
              },
              ".MuiOutlinedInput-input": {
                padding: "14px",
              },
              "& .MuiOutlinedInput-root:hover": {
                background: "#FF8862",
                color: "#fff",
              },
              "& .MuiOutlinedInput-input:focus": {
                outline: "none",
              },
              fieldset: {
                border: "none",
              },
            }}
          >
            <OutlinedInput
              sx={{
                "& input[type='password']::-ms-reveal": {
                  display: "none",
                },
                "& input[type='password']::-ms-clear": {
                  display: "none",
                },
              }}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOff
                        style={{
                          color: "#FF602D",
                        }}
                      />
                    ) : (
                      <Visibility style={{ color: "#FF602D" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              id="password"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values?.password?.trim()}
              onKeyDown={(key) => key?.keyCode === 13 && formik.handleSubmit()}
              placeholder="Password"
            />

            <InputErrorComponent
              touched={formik.touched.password}
              error={formik.errors.password}
            />
          </FormControl>
        </Box>

        <Box
          sx={{
            padding: "0 0.25rem 1rem 0",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <span
            style={{ cursor: "pointer", fontSize: "14px" }}
            onClick={() => {
              setCurrentScreen(2);
              navigate(RouteList?.login, { state: { id: 2 } });
              formik.setErrors([]);
            }}
          >
            Forgot password ?
          </span>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            disabled={formik.isSubmitting || loading}
            onClick={formik.handleSubmit}
            sx={{
              width: "100%",
              maxWidth: "330px",
              textTransform: "none",
              borderRadius: "15px",
              fontSize: "17px",
              background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
              "&:hover": {
                background: "#00075",
              },
              padding: "12px",
            }}
          >
            {formik.isSubmitting || loading ? "Submitting..." : "Login"}
            &nbsp;
            {/* {!formik.isSubmitting && <EastRoundedIcon />} */}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default LoginScreen;
