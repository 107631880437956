import { Box, Typography } from "@mui/material";
const MatricsScrren = () => {
    //   const getData = async (
    //     page = 0,
    //     rowsPerPage = PAGE_SIZE_OPTIONS[0],
    //     search,
    //     prevSearch
    //   ) => {
    //     try {
    //       const count = page * rowsPerPage;
    //       setLoading(true);

    //       // let arg =
    //       //   search?.length > 0
    //       //     ? `${filter} and (username LIKE '%25${search?.trim()}%25' OR email LIKE '%25${search?.trim()}%25' OR phno LIKE '%25${search?.trim()}%25')`
    //       //     : filter;
    //       const res = await AXIOS.get(
    //         `${apiList.help.getAll}?skip=${count}&limit=${rowsPerPage}`
    //       );
    //       if (res?.s) {
    //         const updatedData = res?.r;
    //         const serialData = updatedData?.map((x, i) => {
    //           return { ...x, rowid: i + 1 };
    //         });
    //         setData(serialData);
    //         page === 0 && setTotalData(res?.total ?? 0);
    //         setLoading(false);
    //       } else {
    //         toast.warn(res?.m);
    //         setLoading(false);
    //       }
    //     } catch (error) {
    //       setLoading(false);
    //       console.log(error);
    //     }
    //   };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
            }}
        >
            <Box className="flexBetween">
                <Typography>
                    <h2>Listing Matrics</h2>
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    gap: 1,
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                {/* <Box
          sx={{
            maxWidth: "470px",
            width: "100%",
          }}
        >
          <input
            type="search"
            name="search"
            value={searchTerm}
            placeholder="Search by username"
            style={{
              padding: "13px",
              height: "100%",
              borderRadius: "12px",
              border: "1px solid #FD5D29",
              backgroundColor: "#FFF6F3",
              color: "#FD5D29",
              width: "100%",
            }}
            onChange={(e) => setSearchTerm(e.target.value)}
            id="search"
          />
        </Box> */}
                {/* <Box sx={{}}>
          <DropDownComponent
            value={filter}
            options={filterOption}
            modal={false}
            handleChange={handleFilterChange}
            placeholder={"Status"}
            check={false}
          />
        </Box> */}
            </Box>
            <Box sx={{ height: "calc(100vh - 180px)", mt: 1 }}>List Data</Box>
        </Box>
    );
};

export default MatricsScrren;
