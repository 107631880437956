import { Box, Checkbox, Typography } from "@mui/material";
import DataListingComponent from "../../Component/DataListGrid";
import { useEffect, useRef, useState } from "react";
import DropDownComponent from "../../Component/DropDownComponent";
import { AXIOS } from "../../Component/apiHelper";
import { apiList } from "../../Component/apiList";

import defaultUserImage from "../../Assets/Image/default.webp";
// import view from "../../Assets/Image/newView.webp";
import { toast } from "react-toastify";
// import logo from "../../Assets/Image/question.webp";
// import ConfirmationModal1 from "../../Component/ConfirmModal1";
// import block from "../../Assets/Image/newBlock.webp";
// import unblock from "../../Assets/Image/unblock.webp";
// import deleted from "../../Assets/Image/delete.webp";
import { useNavigate } from "react-router-dom";
// import { RouteList } from "../../Component/RoutesList";
import MailIcon from "@mui/icons-material/Mail";
import DraftsIcon from "@mui/icons-material/Drafts";

const HelpAndSupportScrren = () => {
  const PAGE_SIZE_OPTIONS = [15];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalData, setTotalData] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  // const navigate = useNavigate();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  // const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState(99);
  // const [actionParams, setActionParams] = useState({
  //   userId: null,
  //   status: null,
  // });

  const handleAction = async (id, status) => {
    try
    {
      setLoading(true)
      const f = new FormData();
      f.append("status", status);
      f.append("id", id);
      const res = await AXIOS.post(`${apiList.help.action}`, f);
      if (res?.s)
      {
      await getData(
          page,
          rowsPerPage,
          debouncedSearchTerm,
          searchRef.current,
          filter,
          filterRef.current
        );
      } else
      {
        toast.error(res?.m);
      }
      setLoading(false)
    } catch (error)
    {
      console.log(error);
      setLoading(false)
    }
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const filterOption = [
    { label: "All", value: 9 },
    { label: "Pending", value: "1" },
    { label: "Viewed", value: "2" },
  ];

  const columns = [
    {
      field: "#",
      headerName: "#",
      width: 60,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        return cell?.row?.status !== 2 ? (
          <MailIcon style={{ color: "#fd5d29" }} />
        ) : (
          <DraftsIcon style={{ color: "#665f5d" }} />
        );
      },
    },
    {
      field: "rowid",
      headerName: "Sr.",
      width: 60,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        return cell?.row?.rowid + page * rowsPerPage;
      },
    },
    {
      field: "profile_img",
      headerName: "Profile",
      width: 90,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img
            src={
              cell.row?.user_detail?.profile_img
                ? apiList.baseURL + cell.row?.user_detail?.profile_img
                : defaultUserImage
            }
            alt="profile"
            style={{ borderRadius: "12px", height: "35px", width: "35px" }}
          />
        </div>
      ),
    },

    {
      field: "name",
      headerName: "User Name",
      width: 250,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      // renderCell: (cell) => cell?.row?.user_detail?.username
    },
    {
      field: "subject",
      headerName: "Subject",
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
      renderCell: (cell) => cell?.row?.subject ?? "Subject",
    },
    {
      field: "Description",
      headerName: "Description",
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
      renderCell: (cell) => cell?.row?.Description ?? "Description",
    },

    {
      field: "status",
      headerName: "Mark as Viewed",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        return (
          <Box className="flexCenter" sx={{ height: "100%" }}>
            <Box className="flexCenter" sx={{ height: "100%", gap: 1.5 }}>

              <Checkbox
                checked={cell?.row?.status === 2}
                onChange={() =>
                  handleAction(cell?.row?.id, cell?.row?.status === 1 ? 2 : 1)
                }
                inputProps={{ "aria-label": "controlled" }}
                sx={{
                  color: "#fd5d29",
                  "&.Mui-checked": {
                    color: "#fd5d29",
                  },
                }}
              />


            </Box>
          </Box>
        );
      },
    },
  ];

  const getData = async (
    page = 0,
    rowsPerPage = PAGE_SIZE_OPTIONS[0],
    search,
    prevSearch,
    filter,
    prevFilter
  ) => {
    try
    {
      const count = page * rowsPerPage;
      setLoading(true);
      if (filter !== prevFilter || search !== prevSearch)
      {
        setPage(0);
        setData([]);
      }
      let arg = `?skip=${count}&limit=${rowsPerPage}`;
      if (search?.length > 0)
      {
        arg += `&search=${search?.trim()}`;
      }
      if (filter !== 9 && filter !== 99)
      {
        arg += `&status=${filter}`;
      }

      const res = await AXIOS.get(`${apiList.help.getAll}${arg}`);
      if (res?.s)
      {
        const updatedData = res?.r;
        const serialData = updatedData?.map((x, i) => {
          return { ...x, rowid: i + 1 };
        });
        setData(serialData);
        page === 0 && setTotalData(res?.total ?? 0);
        setLoading(false);
      } else
      {
        setData([]);
        setPage(0);
        setLoading(false);
      }
    } catch (error)
    {
      setLoading(false);
      console.log(error);
    }
  };

  const searchRef = useRef(debouncedSearchTerm);
  const filterRef = useRef(filter);

  useEffect(() => {
    getData(
      page,
      rowsPerPage,
      debouncedSearchTerm,
      searchRef.current,
      filter,
      filterRef.current
    );
    searchRef.current = debouncedSearchTerm;
    filterRef.current = filter;
  }, [debouncedSearchTerm, rowsPerPage, page, filter]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box className="flexBetween">
        <Typography>
          <h2> Help & Support </h2>
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: "470px",
            width: "100%",
          }}
        >
          <input
            type="search"
            name="search"
            value={searchTerm}
            placeholder="Search by username"
            style={{
              padding: "13px",
              height: "100%",
              borderRadius: "12px",
              border: "1px solid #FD5D29",
              backgroundColor: "#FFF6F3",
              color: "#FD5D29",
              width: "100%",
            }}
            onChange={(e) => setSearchTerm(e.target.value)}
            id="search"
          />
        </Box>
        <Box sx={{}}>
          <DropDownComponent
            value={filter}
            options={filterOption}
            modal={false}
            handleChange={handleFilterChange}
            placeholder={"Status"}
            check={false}
          />
        </Box>
      </Box>
      <Box sx={{ height: "calc(100vh - 180px)" }}>
        <DataListingComponent
          data={data}
          PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS}
          loading={loading}
          totalData={totalData}
          page={page}
          columns={columns}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />


      </Box>
    </Box>
  );
};

export default HelpAndSupportScrren;
