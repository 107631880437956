import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import DataListingComponent from "../../Component/DataListGrid";
import app from "../../Assets/Image/User Block-1.webp";
import rej from "../../Assets/Image/User Block.webp";
import { toast } from "react-toastify";
import { apiList } from "../../Component/apiList";
import { AXIOS } from "../../Component/apiHelper";
import ConfirmationModal1 from "../../Component/ConfirmModal1";
import logo from "../../Assets/Image/question.webp";

const RecentBusinessComponent = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [actionParams, setActionParams] = useState({
    userId: null,
    status: null,
    is_approve: null,
  });

  const columns = [
    {
      field: "rowid",
      headerName: "Sr.",
      width: 60,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        return cell?.row?.rowid;
      },
    },

    {
      field: "username",
      headerName: "User Name",
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
      renderCell: (cell) => cell?.row?.user_detail?.username,
    },
    {
      field: "business_name",
      headerName: "Business Name",
      minWidth: 250,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
    },
    // {
    //   field: "category_name",
    //   headerName: "Category Name",
    //   minWidth: 250,
    //   headerClassName: "super-app-theme--header",
    //   headerAlign: "center",
    //   display: "flex",
    //   align: "center",
    //   flex: 1,
    // },

    {
      field: "apprrove",
      headerName: "Action",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        const s = cell?.row?.is_approve;
        return (
          <Box className="flexCenter" sx={{ height: "100%" }}>
            {s == 0 ? (
              <Box className="flexCenter" sx={{ height: "100%", gap: 1.5 }}>
                <img
                  src={rej}
                  alt="Block"
                  style={{ height: "25px", width: "25px" }}
                  onClick={() => {
                    setOpen(true);
                    setActionParams({
                      userId: cell?.row?.id,
                      status: 1,
                    });
                  }}
                />
                <img
                  src={app}
                  style={{ height: "25px", width: "25px" }}
                  alt="View"
                  onClick={() => {
                    setOpen(true);
                    setActionParams({
                      userId: cell?.row?.id,
                      status: -1,
                    });
                  }}
                />
              </Box>
            ) : s === 1 ? (
              <span style={{ color: "green" }}>Approved</span>
            ) : (
              s === -1 && <span style={{ color: "red" }}>Rejected</span>
            )}
          </Box>
        );
      },
    },
  ];

  const handleBusinessAction = async (id, status) => {
    const f = new FormData();
    f.append("user_business_id", id);
    f.append("is_approve", status);
    try
    {
      const res = await AXIOS.post(`${apiList.business.updateBusiness}`, f);
      if (res?.s)
      {
        status === 1
          ? toast.success("Approved successfully")
          : toast.error("Rejected successfully");
        getData();
      } else
      {
        toast.error(res?.m);
      }
    } catch (error)
    {
      console.log(error);
    }
  };

  const getData = async () => {
    try
    {
      setLoading(true);
      const res = await AXIOS.get(`${apiList.business.getAll}?skip=0&limit=10`);
      if (res?.s)
      {
        // const updatedData = count === 0 ? res?.r : [...data, ...res?.r];
        const updatedData = res?.r;
        const serialData = updatedData?.map((x, i) => {
          return { ...x, rowid: i + 1 };
        });
        setData(serialData);
        setLoading(false);
      } else
      {
        toast.warn(res?.m);
        setLoading(false);
      }
    } catch (error)
    {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <DataListingComponent
        data={data}
        loading={loading}
        columns={columns}
        hideFooter={true}
      />
      <ConfirmationModal1
        open={open}
        setOpen={setOpen}
        logo={logo}
        onConfirm={() =>
          handleBusinessAction(
            actionParams.userId,
            actionParams.status,
            actionParams.is_approve
          )
        }
        title="Confirmation"
        message={`Are you sure to ${actionParams?.status === 1 ? "approve" : "reject"
          }?`}
        confirmText="Confirm"
        cancelText="Cancel"
      />
    </>
  );
};

export default RecentBusinessComponent;
