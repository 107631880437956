import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import DropDownComponent from "../../Component/DropDownComponent";
import user from "../../Assets/Image/dashUser.webp";
import list from "../../Assets/Image/dastList.webp";
import rev from "../../Assets/Image/dashRev.webp";

function AnalyticCards() {
  const [userType, setUserType] = useState("default");
  const [minerType, setMinerType] = useState("default");
  const [revenueType, setRevenueType] = useState("default");
  const [userLoader, setuserLoader] = useState(true);
  const [minerLoader, setminerLoader] = useState(true);
  const [revenueLoader, setrevenueLoader] = useState(true);

  const [userCount, setUserCount] = useState(0);
  const [minerCount, setMinerCount] = useState(0);
  const [revenueCount, setRevenueCount] = useState(0);

  const GetUserCount = async (type) => {
    try {
      setuserLoader(true);
      // let res = await GET_API(
      //   `${apiEndpoints.dashboard.getusers}?type=${type}`
      // );
      function formatNumber(num) {
        if (num >= 1_000_000_000) {
          return (num / 1_000_000_000).toFixed(1) + "B"; // Billions
        } else if (num >= 1_000_000) {
          return (num / 1_000_000).toFixed(1) + "M"; // Millions
        } else if (num >= 1_000) {
          return (num / 1_000).toFixed(1) + "K"; // Thousands
        } else {
          return num.toString(); // Less than 1K
        }
      }
      // setUserCount(formatNumber(res.r));
      setuserLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  const GetMinerCount = async (type) => {
    try {
      setminerLoader(true);
      // let res = await GET_API(
      //   `${apiEndpoints.dashboard.getminers}?type=${type}`
      // );

      function formatNumber(num) {
        if (num >= 1_000_000_000) {
          return (num / 1_000_000_000).toFixed(1) + "B"; // Billions
        } else if (num >= 1_000_000) {
          return (num / 1_000_000).toFixed(1) + "M"; // Millions
        } else if (num >= 1_000) {
          return (num / 1_000).toFixed(1) + "K"; // Thousands
        } else {
          return num.toString(); // Less than 1K
        }
      }
      // setMinerCount(formatNumber(res.r));
      setminerLoader(false);
    } catch (error) {
      console.log(error);
    }
  };
  const GetRevenueCount = async (type) => {
    try {
      setrevenueLoader(true);
      // let res = await GET_API(
      //   `${apiEndpoints.dashboard.getrevenue}?type=${type}`
      // );
      // const formatted = res.r.toLocaleString(undefined, {
      //   minimumFractionDigits: 2,
      //   maximumFractionDigits: 2,
      // });

      // setRevenueCount(formatted);
      setrevenueLoader(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetUserCount(userType);
  }, [userType]);
  useEffect(() => {
    GetRevenueCount(revenueType);
  }, [revenueType]);
  useEffect(() => {
    GetMinerCount(minerType);
  }, [minerType]);

  const handleUserFilterChange = (e) => {
    setUserType(e.target.value);
  };
  const handleMinerFilterChange = (e) => {
    setMinerType(e.target.value);
  };

  const handleRevenueFilterChange = (e) => {
    setRevenueType(e.target.value);
  };
  const accesslist = JSON.parse(
    localStorage.getItem("Megalopolist-userData-roles")
  );
  const firstModule = accesslist?.find((module) => module.id === 1);

  const total_user_access = !!firstModule?.access?.find(
    (access) => access.id === 3 && access.admin_user_id !== null
  );
  const total_listing_access = !!firstModule?.access?.find(
    (access) => access.id === 4 && access.admin_user_id !== null
  );
  const revenue_access = !!firstModule?.access?.find(
    (access) => access.id === 5 && access.admin_user_id !== null
  );

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        gap: "1.5rem",
        flexWrap: { xs: "wrap", lg: "nowrap" },
      }}
    >
      {/* 1 */}
      <Box
        sx={{
          width: { xs: "100%", md: "fit-content" },
          borderRadius: "14px",
          padding: "20px",
          background: "#FFF6F3",
          // borderWidth: "0px 2px 2px 0px",
          // borderStyle: "solid",
          // borderColor: "#Fd5d2975",
          display: total_user_access ? "flex" : "none",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "start",
          gap: "0.5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <img src={user} alt="" height={30} width={30} />
            <Typography
              variant="h6"
              sx={{
                fontSize: {
                  xs: "1rem",
                  md: "1.1rem",
                  lg: "1rem",
                  xl: "1.2rem",
                },
              }}
            >
              Total User
            </Typography>
          </Box>
          <DropDownComponent
            options={[
              { id: 0, label: "All", value: "default" },
              { id: 1, label: "Active", value: 1 },
              { id: 2, label: "Suspended", value: 2 },
            ]}
            modal={false}
            value={userType}
            handleChange={handleUserFilterChange}
            startAdornment={false}
            defIcon={true}
          />
        </Box>
        <Typography
          variant="body1"
          sx={{ fontSize: "28px", fontWeight: "700", letterSpacing: "1px" }}
        >
          {userLoader ? <CircularProgress size={20} /> : userCount}
        </Typography>
        {/* <Typography sx={{ fontWeight: "600", fontSize: "15px", mt: "-8px" }}>
          <img src={UPIcon} alt="" style={{ marginRight: "0.35rem" }} />
          <span style={{ color: "#4AD991", marginRight: "0.10rem" }}>
            8.5%
          </span>{" "}
          Up from {fiter}
        </Typography> */}
      </Box>
      {/* 2 */}
      <Box
        sx={{
          width: { xs: "100%", md: "fit-content" },
          borderRadius: "14px",
          padding: "20px",
          background: "#FFF6F3",

          // borderWidth: "0px 2px 2px 0px",
          // borderStyle: "solid",
          // borderColor: "#Fd5d2975",
          display: total_listing_access ? "flex" : "none",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "start",
          gap: "0.5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <img src={list} alt="" height={30} width={30} />
            <Typography
              variant="h6"
              sx={{
                fontSize: {
                  xs: "1rem",
                  md: "1.1rem",
                  lg: "1rem",
                  xl: "1.2rem",
                },
              }}
            >
              Total Listing
            </Typography>
          </Box>
          <DropDownComponent
            options={[
              { id: 0, label: "All", value: "default" },
              { id: 1, label: "Active", value: 1 },
              { id: 2, label: "Expired", value: 2 },
              { id: 2, label: "Flagged", value: 3 },
            ]}
            modal={false}
            value={minerType}
            handleChange={handleMinerFilterChange}
            startAdornment={false}
            defIcon={true}
          />
        </Box>
        <Typography
          variant="body1"
          sx={{ fontSize: "28px", fontWeight: "700", letterSpacing: "1px" }}
        >
          {minerLoader ? <CircularProgress size={20} /> : minerCount}
        </Typography>
        {/* <Typography sx={{ fontWeight: "600", fontSize: "15px", mt: "-8px" }}>
          <img src={UPIcon} alt="" style={{ marginRight: "0.35rem" }} />
          <span style={{ color: "#4AD991", marginRight: "0.10rem" }}>
            8.5%
          </span>{" "}
          Up from {fiter}
        </Typography> */}
      </Box>
      {/* 3 */}
      <Box
        sx={{
          width: { xs: "100%", md: "fit-content" },
          borderRadius: "14px",
          padding: "20px",
          background: "#FFF6F3",
          // borderWidth: "0px 2px 2px 0px",
          // borderStyle: "solid",
          // borderColor: "#Fd5d2975",
          display: revenue_access ? "flex" : "none",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "start",
          gap: "0.5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <Box sx={{ height: { xs: 24, md: 30 }, width: { xs: 24, md: 30 } }}>
              <img src={rev} alt="" height={"100%"} width={"100%"} />
            </Box>
            <Typography
              variant="h6"
              sx={{
                fontSize: {
                  xs: "1rem",
                  md: "1.1rem",
                  lg: "1rem",
                  xl: "1.2rem",
                },
              }}
            >
              Revenue Matrix
            </Typography>
          </Box>
          <DropDownComponent
            options={[
              { id: 0, label: "All", value: "default" },
              { id: 1, label: "This Month", value: 1 },
              { id: 1, label: "This Year", value: 2 },
              { id: 2, label: "Ads Revenue", value: 3 },
            ]}
            modal={false}
            value={revenueType}
            handleChange={handleRevenueFilterChange}
            startAdornment={false}
            defIcon={true}
          />
        </Box>
        <Typography
          variant="body1"
          sx={{ fontSize: "28px", fontWeight: "700", letterSpacing: "1px" }}
        >
          {revenueLoader ? (
            <CircularProgress size={20} />
          ) : (
            <>$ {revenueCount}</>
          )}
        </Typography>
        {/* <Typography sx={{ fontWeight: "600", fontSize: "15px", mt: "-8px" }}>
          <img src={DownIcon} alt="" style={{ marginRight: "0.35rem" }} />
          <span style={{ color: "#FF0000", marginRight: "0.10rem" }}>
            8.5%
          </span>{" "}
          Down from {fiter}
        </Typography> */}
      </Box>
    </Box>
  );
}

export default AnalyticCards;
