import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <App />
      <ToastContainer
        theme="colored"
        autoClose={2500}
        position="top-right"
        closeOnClick
        transition={Zoom}
      />
    </BrowserRouter>
  // </React.StrictMode>
);
