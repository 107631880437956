import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AXIOS } from "../../Component/apiHelper";
import { apiList } from "../../Component/apiList";
import { toast } from "react-toastify";
import DataListingComponent from "../../Component/DataListGrid";
import ConfirmationModal1 from "../../Component/ConfirmModal1";
import dlt from "../../Assets/Image/delete.webp";
import RatingStar from "../../Component/RatingStarComponent";
import defaultUserImage from "../../Assets/Image/default.webp";

import moment from "moment";

const ReviewRatingDataComponent = ({ businessId }) => {
    const PAGE_SIZE_OPTIONS = [10];
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
    const [totalData, setTotalData] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    // const navigate = useNavigate();
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
    const [filter, setFilter] = useState(99);
    const [open, setOpen] = useState(false);
    const [actionParams, setActionParams] = useState("");

    const columns = [
        {
            field: "rowid",
            headerName: "Sr.",
            width: 60,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            display: "flex",
            align: "center",
            renderCell: (cell) => {
                return cell?.row?.rowid + page * rowsPerPage;
            },
        },
        {
            field: "profile_img",
            headerName: "Profile",
            width: 90,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            display: "flex",
            align: "center",
            renderCell: (cell) => (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                    }}
                >
                    <img
                        src={
                            cell?.row?.user_details?.profile_img
                                ? apiList.baseURL + cell?.row?.user_details?.profile_img
                                : defaultUserImage
                        }
                        alt="profile"
                        style={{ borderRadius: "12px", height: "40px", width: "40px" }}
                    />
                </div>
            ),
        },

        {
            field: "username",
            headerName: "User Name",
            minWidth: 200,
            maxWidth: 300,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            display: "flex",
            align: "center",
            flex: 1,
            renderCell: (cell) => cell?.row?.user_details?.username,
        },

        {
            field: "review",
            headerName: "Reviews",
            minWidth: 160,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            display: "flex",
            align: "center",
            flex: 1,
        },
        {
            field: "business_name",
            headerName: "Business Name",
            minWidth: 200,
            maxWidth: 300,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            display: "flex",
            align: "center",
            flex: 1,
            renderCell: (cell) => cell?.row?.user_business?.business_name ?? "-",
        },
        {
            field: "rating",
            headerName: "Rating",
            width: 160,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            display: "flex",
            align: "center",
            renderCell: ({ row }) => {
                return <RatingStar rating={row?.rating_point ?? 0} />;
            },
        },
        {
            field: "created_at",
            headerName: "Date",
            width: 160,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            display: "flex",
            align: "center",
            renderCell: ({ row }) => {
                return moment(row?.created_at).local().format("lll");
            },
        },

        {
            field: "status",
            headerName: "Action",
            width: 100,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            display: "flex",
            align: "center",
            renderCell: (cell) => {
                const s = cell?.row?.status;
                return (
                    <Box className="flexCenter" sx={{ height: "100%" }}>
                        <Box className="flexCenter" sx={{ height: "100%", gap: 1.5 }}>
                            <img
                                src={dlt}
                                alt="Block"
                                style={{ height: "25px", width: "25px", cursor: "pointer" }}
                                onClick={() => {
                                    setActionParams(cell?.row?.id);
                                    setOpen(true);
                                }}
                            />
                        </Box>
                    </Box>
                );
            },
        },
    ];

    const handleAction = async (id) => {
        try
        {
            const res = await AXIOS.get(`${apiList.rating.deleteById}?id=${id}`);
            if (res?.s)
            {
                toast.error("Deleted successfully");
                getData(
                    page,
                    rowsPerPage
                    // filter,
                    // filterRef.current,
                    // debouncedSearchTerm,
                    // searchRef.current
                );
            } else
            {
                toast.error(res?.m);
            }
        } catch (error)
        {
            console.log(error);
        }
    };

    const getData = async (
        id,
        page = 0,
        rowsPerPage = PAGE_SIZE_OPTIONS[0]
        // filter,
        // prevFilter,
        // search,
        // prevSearch
    ) => {
        try
        {
            const count = page * rowsPerPage;
            setLoading(true);
            // if (filter !== prevFilter || search !== prevSearch)
            // {
            //     setPage(0);
            //     setData([]);
            // }
            let arg = `&skip=${count}&limit=${rowsPerPage}`;
            // if (search?.length > 0)
            // {
            //     arg += `&search=${search?.trim()}`;
            // }

            // if (filter !== 9 && filter !== 99)
            // {
            //     arg += `&rating_point=${filter}`;
            // }

            const res = await AXIOS.get(
                `${apiList.rating.getAll}?user_business_id=${id}${arg}`
            );
            if (res?.s)
            {
                const updatedData = res?.r;
                const serialData = updatedData?.map((x, i) => {
                    return { ...x, rowid: i + 1 };
                });
                setData(serialData);
                page === 0 && setTotalData(res?.total ?? 0);
                setLoading(false);
            } else
            {
                setData([]);
                setPage();
                toast.warn(res?.m);
                setLoading(false);
            }
        } catch (error)
        {
            setLoading(false);
            console.log(error);
        }
    };

    const handlePageChange = (e, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        businessId && getData(
            businessId,
            page,
            rowsPerPage
            //   filter,
            //   filterRef.current,
            //   debouncedSearchTerm,
            //   searchRef.current
        );
        // searchRef.current = debouncedSearchTerm;
        // filterRef.current = filter;
    }, [debouncedSearchTerm, filter, rowsPerPage, page, businessId]);
    return (
        <Box sx={{ height: "calc(100vh - 500px)",mt:2 }}>
            <DataListingComponent
                data={data}
                PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS}
                loading={loading}
                totalData={totalData}
                page={page}
                columns={columns}
                rowsPerPage={rowsPerPage}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
            />

            <ConfirmationModal1
                open={open}
                setOpen={setOpen}
                onConfirm={() => handleAction(actionParams)}
                title="Confirm Delete Action"
                message="Are you sure want to delete?"
                confirmText="Confirm"
                cancelText="Cancel"
            />
        </Box>
    );
};

export default ReviewRatingDataComponent;
