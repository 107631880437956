import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { AXIOS } from "../../Component/apiHelper";
import { apiList } from "../../Component/apiList";
import { toast } from "react-toastify";
import { RouteList } from "../../Component/RoutesList";

const UpdatePasswordScreen = ({ setCurrentScreen }) => {
  const navigate = useNavigate();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickShowNewPassword = () => setShowNewPassword((prev) => !prev);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((prev) => !prev);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: yup.object({
      newPassword: yup
        .string()
        .required("New password is required")
        .min(6, "Password must be at least 6 characters")
        .max(20, "Password cannot exceed 20 characters"),
      confirmPassword: yup
        .string()
        .required("Confirm password is required")
        .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try
      {
        let fp_token = localStorage.getItem("fp_token");
        const res = await AXIOS.post(apiList.auth.updatePassword, {
          password: values.newPassword,
          fp_token: fp_token,
        });
        if (res?.s)
        {
          toast.success("Password updated successfully!");
          setCurrentScreen(1);
          localStorage.removeItem("email");
          localStorage.removeItem("hash");
          navigate(RouteList?.login, { state: { id: 1 } });
        } else
        {
          toast.error(res?.m || "Failed to update password");
        }
      } catch (error)
      {
        toast.error("An error occurred. Please try again.");
      } finally
      {
        setLoading(false);
      }
    },
  });

  return (
    <Box sx={{ maxWidth: "450px", margin: "auto" }}>
      <h2 style={{ color: "#000" }}>Update Password</h2>
      <p style={{ color: "#000000B2", marginBottom: "1rem" }}>
        Please enter your new password and confirm it below.
      </p>
      <form onSubmit={formik.handleSubmit}>
        {/* New Password Field */}
        <label htmlFor="newPassword" style={{ fontSize: "14px" }}>
          New Password
        </label>
        <FormControl
          variant="outlined"
          fullWidth
          error={
            formik.touched.newPassword && Boolean(formik.errors.newPassword)
          }
          sx={{
            marginTop: "0.5rem",
            ".MuiInputBase-root": {
              borderRadius: "10px",
              background: "#FD5D2914",
              border: "1px solid #FD5D2966",
            },
          }}
        >
          <OutlinedInput
            id="newPassword"
            name="newPassword"
            type={showNewPassword ? "text" : "password"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.newPassword?.trim()}
            placeholder="Enter new password"
            sx={{
              margin: "0.25rem 0 0 0",
              ".MuiInputBase-root": {
                borderRadius: "15px",
                background: "#FD5D2914",
                color: "#000",
                border: "1px solid #FD5D2966",
              },
              ".MuiOutlinedInput-input": {
                padding: "14px",
              },
              "& .MuiOutlinedInput-root:hover": {
                background: "#FF8862",
                color: "#fff",
              },
              "& .MuiOutlinedInput-input:focus": {
                outline: "none",
              },
              fieldset: {
                border: "none",
              },
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowNewPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Box
          sx={{
            fontSize: "12px",
            px: 1.5,
            letterSpacing: "0.03rem",
            py: 0.25,
            height: "20px",
            color: "#d32f2f",
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
          }}
        >
          {formik.touched.newPassword && formik.errors.newPassword
            ? formik.errors.newPassword
            : " "}
        </Box>

        {/* Confirm Password Field */}
        <label htmlFor="confirmPassword" style={{ fontSize: "14px" }}>
          Confirm Password
        </label>
        <FormControl
          variant="outlined"
          fullWidth
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
          sx={{
            marginTop: "0.5rem",
            ".MuiInputBase-root": {
              borderRadius: "10px",
              background: "#FD5D2914",
              border: "1px solid #FD5D2966",
            },
          }}
        >
          <OutlinedInput
            id="confirmPassword"
            name="confirmPassword"
            type={showConfirmPassword ? "text" : "password"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword?.trim()}
            placeholder="Re-enter new password"
            sx={{
              margin: "0.25rem 0 0 0",
              ".MuiInputBase-root": {
                borderRadius: "15px",
                background: "#FD5D2914",
                color: "#000",
                border: "1px solid #FD5D2966",
              },
              ".MuiOutlinedInput-input": {
                padding: "14px",
              },
              "& .MuiOutlinedInput-root:hover": {
                background: "#FF8862",
                color: "#fff",
              },
              "& .MuiOutlinedInput-input:focus": {
                outline: "none",
              },
              fieldset: {
                border: "none",
              },
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Box
          sx={{
            fontSize: "12px",
            px: 1.5,
            letterSpacing: "0.03rem",
            py: 0.25,
            height: "20px",
            color: "#d32f2f",
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
          }}
        >
          {formik.touched.confirmPassword && formik.errors.confirmPassword
            ? formik.errors.confirmPassword
            : " "}
        </Box>

        <Button
          type="submit"
          variant="contained"
          disabled={loading}
          sx={{
            mt: 2,
            width: "100%",
            textTransform: "none",
            borderRadius: "10px",
            fontSize: "16px",
            padding: "12px",
            background: "linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)",
            "&:hover": {
              background: "#FF602D",
            },
          }}
        >
          {loading ? "Updating..." : "Update Password"}
        </Button>
      </form>
    </Box>
  );
};

export default UpdatePasswordScreen;
