import {
  Box,
  Checkbox,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import filter from "../../src/Assets/Image/filter.png";
const DropDownComponent = ({
  value,
  handleChange,
  options,
  setOpen,
  check,
  placeholder,
  defIcon = null,
  startAdornment = true,
}) => {
  return (
    <FormControl
      sx={{
        backgroundColor: "#FD5D29",
        borderRadius: "12px",
        color: "#FFF",
        width: "fit-content",
        "& .MuiOutlinedInput-input:focus ": {
          borderRadius: "12px",
        },
        ".MuiSelect-select": {
          color: "#FFF",
          borderRadius: "12px",
        },
        "& .MuiInputBase-root": {
          border: "none",
          borderRadius: "12px",
        },
        "& fieldset": {
          border: "none",
          borderRadius: "12px",
        },
      }}
    >
      <Select
        value={value || ""}
        onChange={handleChange}
        sx={{
          ".MuiSelect-select": {
            padding: 1,
            outline: "none",
          },
          ".MuiSelect-icon": {
            color: "#FFF",
          },
        }}
        IconComponent={defIcon ? undefined : () => null}
        startAdornment={
          startAdornment && (
            <InputAdornment position="start">
              <img src={filter} alt="" height={20} width={20} />
            </InputAdornment>
          )
        }
        MenuProps={{
          PaperProps: {
            sx: {
              backgroundColor: "#FFF6F3",
              color: "#000",
              fontSize: "14px",
            },
          },
        }}
        renderValue={(selected) => {
          const selectedOption = options.find(
            (option) => option.value === selected
          );
          return selectedOption ? selectedOption.label : placeholder;
        }}
      >
        <MenuItem value={99} sx={{ display: "none" }}>
          {placeholder}
        </MenuItem>

        {options?.map((x, i) => (
          <MenuItem
            sx={{
              "&.MuiMenuItem-root:hover": {
                color: "ffffff95",
                "& .MuiCheckbox-root": {
                  color: "ffffff95",
                },
              },
            }}
            key={i}
            value={x.value}
          >
            <Box sx={{ display: "flex", alignItems: "center", p: 0.5 }}>
              {check && (
                <Checkbox
                  checkedIcon={
                    <CheckBoxRoundedIcon
                      style={{ color: "#D8D4FF", fontSize: "20px" }}
                    />
                  }
                  icon={
                    <CheckBoxOutlineBlankRoundedIcon
                      style={{ color: "#D8D4FF", fontSize: "20px" }}
                    />
                  }
                  checked={x.value === value}
                />
              )}
              {x.label}
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropDownComponent;
