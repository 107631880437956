import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import logo from "../../Assets/Image/logo.webp";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ForgotPasswordScreen from "./ForgotPassword";
import VerifyOTPScreen from "./VerifyOTP";
import UpdatePasswordScreen from "./UpdatePassword";
import LoginScreen from "./LoginScreen";

const AuthScreen = () => {
  const [currentScreen, setCurrentScreen] = useState(() => {
    return parseInt(localStorage.getItem("currentScreen")) || 1;
  });

  useEffect(() => {
    localStorage.setItem("currentScreen", currentScreen);
  }, [currentScreen]);
  return (
    <Box
      sx={{
        background: `radial-gradient(95.88% 96.35% at 50.13% 48.46%, #FF8862 0%, #FD5D29 100%)`,
        height: "100vh",
        width: "100%",
        padding: "1rem",
      }}
      className="flexCenter"
    >
      <Box
        sx={{
          borderRadius: "2rem",
          maxWidth: "600px",
          paddingX: { xs: "3rem", md: "3rem" },
          paddingY: { xs: "2rem", md: "2rem" },
          background: `#fff`,
          borderWidth: "0px 3px 3px 0px",
          borderStyle: "solid",
          borderColor: "#FFFFFF66",
          backdropFilter: "blur(1px)",
          color: "#000",
        }}
      >
        <span
          onClick={() => setCurrentScreen((prev) => Math.max(1, prev - 1))}
          style={{
            textTransform: "none",
            position: "absolute",
            color: "#DA4312",
            fontSize: "14px",
            cursor: "pointer",
            display: currentScreen === 1 ? "none" : "flex",
          }}
        >
          <KeyboardBackspaceIcon />
        </span>
        <Box className="flexCenter" sx={{ pb: 2 }}>
          <img src={logo} alt="logo" style={{ height: "100px" }} />
        </Box>
        {currentScreen === 1 ? (
          <LoginScreen setCurrentScreen={setCurrentScreen} />
        ) : currentScreen === 2 ? (
          <ForgotPasswordScreen setCurrentScreen={setCurrentScreen} />
        ) : currentScreen === 3 ? (
          <VerifyOTPScreen setCurrentScreen={setCurrentScreen} />
        ) : (
          currentScreen === 4 && (
            <UpdatePasswordScreen setCurrentScreen={setCurrentScreen} />
          )
        )}
      </Box>
    </Box>
  );
};

export default AuthScreen;
