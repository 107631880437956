import { Box, Button, Typography } from "@mui/material";
import DataListingComponent from "../../Component/DataListGrid";
import { useEffect, useRef, useState } from "react";
import { AXIOS } from "../../Component/apiHelper";
import { apiList } from "../../Component/apiList";
import { toast } from "react-toastify";
import logo from "../../Assets/Image/question.webp";
import ConfirmationModal1 from "../../Component/ConfirmModal1";
import block from "../../Assets/Image/newBlock.webp";
import unblock from "../../Assets/Image/unblock.webp";
import deleted from "../../Assets/Image/delete.webp";
import edit from "../../Assets/Image/edit.webp";
import AdminRoleModal from "./AdminRoleModal";
const AdminRoleScreen = () => {
  const PAGE_SIZE_OPTIONS = [15];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalData, setTotalData] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [adminId, setAdminId] = useState("");
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [typeCheck, setTypeCheck] = useState(null);
  const [openAdminRoleModal, setOpenAdminRoleModal] = useState(false);
  const [singleDetails, setSingleUserDetails] = useState({});
  const [actionParams, setActionParams] = useState({
    userId: null,
    status: null,
  });
  const handleAction = async (userId, status) => {
    try
    {
      const f = new FormData();
      f.append("status", status);
      f.append("user_id", userId);
      const res = await AXIOS.post(`${apiList.user.action}`, f);
      if (res?.s)
      {
        toast.success("Deleted Successful");
        getData(page, rowsPerPage, debouncedSearchTerm, searchRef.current);
      } else
      {
        toast.error(res?.m);
      }
    } catch (error)
    {
      console.log(error);
    }
  };

  const columns = [
    {
      field: "rowid",
      headerName: "Sr.",
      width: 60,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        return cell?.row?.rowid + page * rowsPerPage;
      },
    },
    {
      field: "username",
      headerName: "User Name",
      width: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
      renderCell: (cell) => {
        return cell?.row?.username ? cell?.row?.username : "NA";
      },
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
    },
    {
      field: "role",
      headerName: "Role",
      width: 90,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
      renderCell: (cell) => {
        return cell?.row?.role === 4 ? "Sub-admin" : "NA";
      },
    },
    {
      field: "total_access",
      headerName: "No. of Access",
      width: 90,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
      renderCell: (cell) => {
        return cell?.row?.total_access ? cell?.row?.total_access : "NA";
      },
    },

    {
      field: "status",
      headerName: "Action",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",

      renderCell: (cell) => {
        return (
          <Box className="flexCenter" sx={{ height: "100%" }}>
            <Box className="flexCenter" sx={{ height: "100%", gap: 1.5 }}>
              <img
                src={edit}
                style={{ height: "29px", width: "29px" }}
                alt="edit"
                onClick={() => {
                  handleAddEditModels(cell?.row);
                }}
              />

              {/* <img
                src={view}
                onClick={() => {
                  setUserDetailsModalId(cell?.row?.id);
                  // handleOpen()
                }}
                style={{ height: "29px", width: "29px" }}
                alt="View"
              /> */}
              <img
                src={deleted}
                onClick={() => {
                  setOpen(true);
                  setActionParams({
                    userId: cell?.row?.id,
                    status: -1,
                  });
                  //   setUserDetailsModalId(cell?.row?.id);  n
                  // handleOpen()
                }}
                style={{ height: "29px", width: "29px" }}
                alt="View"
              />
            </Box>
          </Box>
        );
      },
    },
  ];

  const getData = async (
    page = 0,
    rowsPerPage = PAGE_SIZE_OPTIONS[0],
    search,
    prevSearch
  ) => {
    try
    {
      const count = page * rowsPerPage;
      setLoading(true);
      if (search !== prevSearch)
      {
        setPage(0);
        setData([]);
      }
      let arg =
        search?.length > 0
          ? `search=${search?.trim()}&skip=${count}`
          : `skip=${count}`;
      const res = await AXIOS.get(`${apiList.subAdmin.get}?${arg}`);
      if (res?.s)
      {
        const updatedData = res?.r;
        const serialData = updatedData?.map((x, i) => {
          return { ...x, rowid: i + 1 };
        });
        setData(serialData);
        page === 0 && setTotalData(res?.total ?? 0);
        setLoading(false);
      } else
      {
        toast.warn(res?.m);
        setLoading(false);
      }
    } catch (error)
    {
      setLoading(false);
      console.log(error);
    }
  };

  const searchRef = useRef(debouncedSearchTerm);

  useEffect(() => {
    getData(page, rowsPerPage, debouncedSearchTerm, searchRef.current);
    searchRef.current = debouncedSearchTerm;
  }, [debouncedSearchTerm, rowsPerPage, page]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };
  const handleAddEditModels = (data) => {
    if (data)
    {
      setTypeCheck("Edit");
      setSingleUserDetails(data);
      setOpenAdminRoleModal(true);
    } else
    {
      setTypeCheck("Add");
      setSingleUserDetails({});
      setOpenAdminRoleModal(true);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box className="flexBetween">
        <Typography>
          <h2> Admin Role Management </h2>
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: "470px",
            width: "100%",
          }}
        >
          <input
            type="search"
            name="search"
            value={searchTerm?.trimStart()}
            placeholder="Search by name and email"
            style={{
              padding: "13px",
              height: "100%",
              borderRadius: "12px",
              border: "1px solid #FD5D29",
              backgroundColor: "#FFF6F3",
              color: "#FD5D29",
              width: "100%",
            }}
            onChange={(e) => setSearchTerm(e.target.value?.trimStart())}
            id="search"
          />
        </Box>
        {/* <Box sx={{}}>
          <DropDownComponent
            value={filter}
            options={filterOption}
            modal={false}
            handleChange={handleFilterChange}
            placeholder={"Status"}
            check={false}
          />
        </Box> */}
        <Box sx={{}}>
          <Button
            sx={{
              width: "150px",
              textTransform: "none",
              borderRadius: "15px",
              fontSize: "17px",
              color: "#FFF",
              background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
              "&:hover": {
                background: "#00075",
              },
              padding: "8px",
            }}
            onClick={() => handleAddEditModels()}
          >
            Add
          </Button>
        </Box>
      </Box>
      <Box sx={{ height: "calc(100vh - 180px)" }}>
        <DataListingComponent
          data={data}
          PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS}
          loading={loading}
          totalData={totalData}
          page={page}
          columns={columns}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
        <AdminRoleModal
          open={openAdminRoleModal}
          setOpen={setOpenAdminRoleModal}
          typeCheck={typeCheck}
          singleDetails={singleDetails}
          setSingleUserDetails={setSingleUserDetails}
          getData={() =>
            getData(page, rowsPerPage, debouncedSearchTerm, searchRef.current)
          }
        />
        <ConfirmationModal1
          open={open}
          setOpen={setOpen}
          logo={logo}
          onConfirm={() =>
            handleAction(actionParams.userId, actionParams.status)
          }
          title="Confirm Action"
          message="Are you sure?"
          confirmText="Confirm"
          cancelText="Cancel"
        />
      </Box>
    </Box>
  );
};

export default AdminRoleScreen;
