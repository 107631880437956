import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Autocomplete, Modal, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useFormik } from "formik";
import * as Yup from "yup";
import edit_orange from "../../Assets/Image/Edit_orange.webp";
import { AXIOS } from "../../Component/apiHelper";
import { apiList } from "../../Component/apiList";
import { toast } from "react-toastify";
import user from "../../Assets/Image/default.webp";
import Loader from "../../Component/Loader";
import InputErrorComponent from "../../Component/InputErrorComponent";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  width: "calc(100% - 50px)",
  bgcolor: "background.paper",
  outline: "none",
  boxShadow: 24,
  borderRadius: "12px",
  minHeight: "200px",
  p: 4,
};

export default function UserEditModal({
  handleOpenButton,
  title = "Edit User",
  confirmText = "Save",
  cancelText = "Cancel",
  onConfirm,
  onCancel,
  userid,
  initialData = {},
  getUserData,
}) {
  const [open, setOpen] = React.useState(false);
  const [preview, setPreview] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectState, setSelectState] = useState(null);
  const [loading, setloading] = useState(true);

  const formik = useFormik({
    initialValues: {
      photo: initialData.photo || "",
      username: initialData.username || "",
      city: initialData.city || "",
      country: initialData.country || "",
      state: initialData.state || "",
      zipcode: initialData.zipcode || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Required"),
      country: Yup.object().required("Required"),
      state: Yup.object().required("Required"),
      city: Yup.object().required("Required"),
      zipcode: Yup.number().required("Required"),
    }),
    onSubmit: async (values) => {
      try
      {
        const f = new FormData();
        if (
          formik.values.photo?.file &&
          formik.values.photo !== initialData.photo
        )
        {
          f.append("profile_img", formik.values.photo?.file);
        }
        f.append("username", values.username);
        f.append("country_id", values.country?.id);
        f.append("state_id", values.state?.id);
        f.append("city_id", values.city?.id);
        f.append("zipcode", values.zipcode);
        let res = await AXIOS.post(apiList.user.updateprofile + userid, f);
        if (res?.s)
        {
          toast.success("Updated Successfully");
          getUserData();
        }
        setOpen(false);
      } catch (error)
      {
        console.log(error);
      }
    },
  });
  const handleOpen = async () => {
    setloading(true);
    setOpen(true);
    await getDetailById(userid);
    setloading(false);
  };
  const handleClose = () => {
    setOpen(false);
    if (typeof onCancel === "function") onCancel();
    setTimeout(() => {
      formik.resetForm();
    }, 300);
  };

  const getDetailById = async (id) => {
    try
    {
      // Fetch user details
      const res = await AXIOS.get(
        `${apiList.user.getDetailById}?user_id=${id}`
      );
      if (res?.s)
      {
        const { r } = res;
        formik.setFieldValue("photo", r.profile_img);
        formik.setFieldValue("username", r.username);
        formik.setFieldValue("cc", r.phone_cc);
        formik.setFieldValue("phone", r.phno);
        formik.setFieldValue("zipcode", r.zipcode);
        const newCountryList = await getCountryList();
        const userCountry = newCountryList.find((e) => e.id === r.country_id);
        setCountryList(newCountryList);
        formik.setFieldValue("country", userCountry ?? "");
        const newStateList = await getStateList(userCountry.id);
        const userState = await newStateList?.find((e) => e.id === r.state_id);
        setStateList(newStateList);
        formik.setFieldValue("state", userState ?? "");
        const newCityList = await getCityList(userState.id);
        const userCity = await newCityList?.find((e) => e.id == r?.city_id);
        formik.setFieldValue("city", userCity ?? "");
        setCityList(newCityList);
      } else
      {
        throw new Error("Invalid user details response");
      }
    } catch (error)
    {
      console.error("Error fetching user details:", error.message);
      // Optional: Handle UI state updates for errors
    }
  };

  const getCountryList = async () => {
    try
    {
      const response = await AXIOS(apiList.CountryStateCity.getCountries);
      if (response && response.s === 1)
      {
        const countries = response.r;
        setCountryList(countries);
        return countries;
      }
    } catch (error)
    {
      console.error("Error fetching Country list:", error);
    }
  };

  const getStateList = async (countryId) => {
    try
    {
      const response = await AXIOS(
        `${apiList.CountryStateCity.getStates}[${countryId},1]`
      );
      if (response && response.s === 1)
      {
        const states = response.r;

        setStateList(states);
        return states;
      }
    } catch (error)
    {
      console.error("Error fetching State list:", error);
    }
  };
  const getCityList = async (stateId) => {
    try
    {
      const response = await AXIOS(
        `${apiList.CountryStateCity.getCities}[${stateId},1]`
      );

      if (response && response.s === 1)
      {
        const cites = response.r;
        setCityList(cites);
        return cites;
      }
    } catch (error)
    {
      console.error("Error fetching City list:", error);
    }
  };
  useEffect(() => {
    if (userid)
    {
      getDetailById(userid);
    }
  }, [userid]);

  useEffect(() => {
    getCountryList();
  }, [userid]);
  useEffect(() => {
    if (selectedCountry)
    {
      getStateList(selectedCountry?.id);
    }
  }, [selectedCountry]);
  useEffect(() => {
    if (selectState)
    {
      getCityList(selectState.id);
    }
  }, [selectState]);

  return (
    <div>
      {/* <Box > */}
      {/* {handleOpenButton && handleOpenButton()} */}
      {/* <img src={edit_orange} alt="logo" style={{ height: "25px" }} /> */}
      <Button
        onClick={handleOpen}
        variant="contained"
        sx={{
          width: "100%",
          maxWidth: "300px",
          textTransform: "none",
          borderRadius: "12px",
          background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
        }}
      >
        Edit
      </Button>
      {/* </Box> */}
      <Modal
        aria-labelledby="edit-user-modal-title"
        aria-describedby="edit-user-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 300 } }}
      >
        <Fade in={open}>
          <Box sx={{ ...style, textAlign: "center" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // marginTop: "10px",
                borderBottom: "1px solid #eee",
                paddingBottom: "10px",
              }}
            >
              <h3 style={{ color: "#fd5d29" }}> Edit User Information</h3>
            </div>
            {loading ? (
              <Box className="flexCenter" sx={{ height: "200px" }}>
                <Loader />
              </Box>
            ) : (
              <>
                <Box
                  className="flexCenter"
                  onClick={() =>
                    document.getElementById("upload-photo").click()
                  }
                  sx={{
                    cursor: "pointer",
                    m: "auto",
                    mb: "0.5rem",
                    height: "100px",
                    width: "100px",
                    position: "relative",
                    border: "2px solid #292D3280",
                    borderRadius: "50%",
                  }}
                >
                  <img
                    src={
                      preview
                        ? preview
                        : formik.values.photo
                          ? apiList.baseURL + formik.values.photo
                          : user
                    }
                    alt="User profile"
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "50%",
                      objectFit: "cover",
                      border: "1px solid #ccc",
                      position: "relative",
                    }}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      right: 10,
                      background: "#FF602D",
                      borderRadius: "5px",
                      padding: "3px 3px 0",
                      cursor: "pointer",
                    }}
                  >
                    <EditIcon sx={{ color: "#fff", fontSize: "16px" }} />
                  </Box>
                  <input
                    id="upload-photo"
                    type="file"
                    name="photo"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      if (file)
                      {
                        const previewUrl = URL.createObjectURL(file);
                        formik.setFieldValue("photo", {
                          file: file,
                          url: previewUrl,
                        });
                        setPreview(previewUrl);
                      }
                    }}
                  />
                </Box>
                <InputErrorComponent
                  touched={formik.touched.photo}
                  error={formik.errors.photo}
                />
                <form onSubmit={formik.handleSubmit}>
                  <input
                    type="text"
                    name="username"
                    placeholder="Username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    style={{
                      padding: "14px",
                      // marginTop: "10px",
                      borderRadius: "12px",
                      border: "1px solid #FD5D2966",
                      backgroundColor: "#FFF6F3",
                      color: "#FD5D29",
                      width: "100%",
                      fontSize: "16px",
                    }}
                  />
                  <InputErrorComponent
                    touched={formik.touched.username}
                    error={formik.errors.username}
                  />
                  {/* <input
                type="text"
                name="phone"
                placeholder="Phone Number"
                value={formik.values.phone}
                onChange={formik.handleChange}
                style={{
                  padding: "14px",
                 marginTop: "10px",
                  borderRadius: "12px",
                  border: "1px solid #FD5D29",
                  backgroundColor: "#FFF6F3",
                  color: "#FD5D29",
                  width: "100%",
                }}
              /> */}

                  {/* <Box
                    sx={{ display: "flex", gap: "10px", border: "solid red" }}
                  > */}
                  <Box
                    sx={{
                      // display: "flex",
                      // flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Autocomplete
                      value={formik.values.country}
                      onChange={(event, newValue) => {
                        formik.setFieldValue("country", newValue);
                        setSelectedCountry(newValue);
                        formik.setFieldValue("state", "");
                        formik.setFieldValue("city", "");
                      }}
                      getOptionLabel={(data) => data?.country_name ?? ""}
                      options={countryList}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Country"
                          sx={{
                            margin: "0.25rem 0 0 0",
                            ".MuiInputBase-root": {
                              borderRadius: "12px",
                              background: "#FD5D2914",
                              color: "#000",
                              border: "1px solid #FD5D2966",
                            },
                            ".MuiOutlinedInput-input": {
                              padding: "10px",
                              color: "#fd5d29",
                            },
                            // ".MuiInputBase-root:hover": {
                            //   background: "#FF8862",
                            //   color: "#fff",
                            // },

                            "& .MuiSvgIcon-root": {
                              color: "#fd5d29",
                            },

                            ".MuiOutlinedInput-input:focus": {
                              outline: "none",
                            },
                            ".MuiOutlinedInput-root": {
                              padding: "8px",
                            },

                            fieldset: {
                              border: "none",
                            },
                          }}
                        />
                      )}
                    />
                    <InputErrorComponent
                      touched={formik.touched.country}
                      error={formik.errors.country}
                    />
                  </Box>
                  <Box
                    sx={{
                      // display: "flex",
                      // flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Autocomplete
                      value={formik.values.state}
                      onChange={(event, newValue) => {
                        formik.setFieldValue("state", newValue);
                        setSelectState(newValue);

                        formik.setFieldValue("city", "");
                      }}
                      getOptionLabel={(data) => data?.state_name ?? ""}
                      options={stateList}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select State"
                          sx={{
                            margin: "0.25rem 0 0 0",
                            ".MuiInputBase-root": {
                              borderRadius: "12px",
                              background: "#FD5D2914",
                              color: "#000",
                              border: "1px solid #FD5D2966",
                            },
                            ".MuiOutlinedInput-input": {
                              color: "#fd5d29",
                            },
                            // ".MuiInputBase-root:hover": {
                            //   background: "#FF8862",
                            //   color: "#fff",
                            // },

                            "& .MuiSvgIcon-root": {
                              color: "#fd5d29",
                            },

                            ".MuiOutlinedInput-input:focus": {
                              outline: "none",
                            },
                            ".MuiOutlinedInput-root": {
                              padding: "8px",
                            },
                            fieldset: {
                              border: "none",
                            },
                          }}
                        />
                      )}
                    />
                    <InputErrorComponent
                      touched={formik.touched.state}
                      error={formik.errors.state}
                    />
                  </Box>
                  {/* </Box> */}

                  <Box sx={{}}>
                    <Autocomplete
                      value={formik.values.city}
                      onChange={(event, newValue) => {
                        formik.setFieldValue("city", newValue);
                      }}
                      getOptionLabel={(data) => data?.city_name ?? ""}
                      options={cityList}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          id="city"
                          {...params}
                          placeholder="Select City"
                          sx={{
                            // margin: "0.25rem 0 0 0",
                            ".MuiInputBase-root": {
                              borderRadius: "12px",
                              background: "#FD5D2914",
                              color: "#000",
                              border: "1px solid #FD5D2966",
                            },
                            ".MuiOutlinedInput-input": {
                              color: "#fd5d29",
                            },
                            // ".MuiInputBase-root:hover": {
                            //   background: "#FF8862",
                            //   color: "#fff",
                            // },
                            "& .MuiSvgIcon-root": {
                              color: "#fd5d29",
                            },

                            ".MuiOutlinedInput-input:focus": {
                              outline: "none",
                            },
                            ".MuiOutlinedInput-root": {
                              padding: "8px",
                            },
                            fieldset: {
                              border: "none",
                            },
                          }}
                        />
                      )}
                    />
                    <InputErrorComponent
                      touched={formik.touched.city}
                      error={formik.errors.city}
                    />
                  </Box>

                  {/* Zipcode Input */}
                  <input
                    type="text"
                    name="zipcode"
                    placeholder="Zipcode"
                    value={formik.values.zipcode}
                    onChange={formik.handleChange}
                    style={{
                      padding: "14px",
                      // marginTop: "10px",
                      borderRadius: "12px",
                      border: "1px solid #FD5D2966",
                      backgroundColor: "#FFF6F3",
                      color: "#FD5D29",
                      width: "100%",
                      fontSize: "16px",
                    }}
                  />
                  <InputErrorComponent
                    touched={formik.touched.zipcode}
                    error={formik.errors.zipcode}
                  />

                  {/* Action Buttons */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: 1,
                      mt: 2,
                    }}
                  >
                    <Button
                      sx={{
                        width: "100%",
                        maxWidth: "300px",
                        textTransform: "none",
                        borderRadius: "12px",
                        border: "1px solid #FF602D",
                        color: "#FF602D",
                      }}
                      onClick={handleClose}
                    >
                      {cancelText}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        width: "100%",
                        maxWidth: "300px",
                        textTransform: "none",
                        borderRadius: "12px",
                        background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
                      }}
                    >
                      Update
                    </Button>
                  </Box>
                </form>
              </>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
