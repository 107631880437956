import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { AXIOS } from "../../Component/apiHelper";
import { apiList } from "../../Component/apiList";
import { toast } from "react-toastify";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import icon from "../../Assets/Image/addIcon.png";

import { Button, TextField } from "@mui/material";
import Dropzone from "react-dropzone";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  width: 400,
};

export default function CategoryModal({
  open,
  data,
  setOpen,
  type,
  parentId,
  getData,
  lable,
}) {
  let initial = {
    title: "",
    icon: null,
  };
  if (type === "add-main" || type === "update-main") {
    initial = {
      title: "",
      icon: null,
    };
  } else if (type === "add-sub" || type === "update-sub") {
    initial = {
      title: "",
    };
  }
  let validationSchema;
  if (type === "add-main" || type === "update-main") {
    validationSchema = yup.object().shape({
      title: yup
        .string()
        .required("Title is required")
        .matches(/^\S.*\S$|^\S$/, "Title must not start or end with a space"), // No leading spaces
      icon: yup.mixed().required("Icon is required"),
    });
  } else if (type === "add-sub" || type === "update-sub") {
    validationSchema = yup.object().shape({
      title: yup
        .string()
        .required("Title is required")
        .matches(/^\S.*\S$|^\S$/, "Title must not start or end with a space"), // No leading spaces
    });
  }

  const formik = useFormik({
    initialValues: initial,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        // setSubmitting(true)

        let res;
        if (type === "add-main") {
          const f = new FormData();
          f.append("category_name", values.title?.trimStart());
          f.append("image", values.icon.file);
          res = await AXIOS.post(apiList.category.addNewCategory, f);
        } else if (type === "add-sub") {
          const f = new FormData();
          f.append("category_id", parentId);
          f.append("category_sub_name", values.title?.trimStart());
          // f.append("image", values.icon);
          res = await AXIOS.post(apiList.category.addSubCat, f);
        } else if (type === "update-main") {
          const f = new FormData();
          // f.append("category_id", parentId);
          f.append("category_name", values.title?.trimStart());
          values.icon.file && f.append("image", values.icon.file);
          if (data?.id)
            res = await AXIOS.post(
              `${apiList.category.updateMainCat}${data?.id}`,
              f
            );
        } else if (type === "update-sub") {
          const f = new FormData();
          f.append("category_sub_name", values.title?.trimStart());
          // f.append("image", values.icon);
          if (data?.id)
            res = await AXIOS.post(
              `${apiList.category.UpdateSubCat}${data?.id}`,
              f
            );
        }

        if (res?.s) {
          toast.success(
            type === "update-main" || type === "update-sub"
              ? "Updated successfully"
              : "Added successfully"
          );
          getData();
        } else {
          toast.error(res?.m);
        }
        handleClose();
      } catch (error) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    (open?.category_name || open?.category_sub_name) &&
      formik.setFieldValue(
        "title",
        open?.category_name || open?.category_sub_name
      );
    (data?.category_name || data?.category_sub_name) &&
      formik.setFieldValue(
        "title",
        data?.category_name || data?.category_sub_name
      );
    open?.image && formik.setFieldValue("icon", open?.image);
    data?.image && formik.setFieldValue("icon", data?.image);
  }, [open, type]);

  const handleClose = () => {
    setOpen("");
    formik.resetForm();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 300,
          },
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              ...style,
              overflow: "auto",
              maxHeight: "calc(100vh - 100px)",
              maxWidth: {
                xs: "calc(100% - 50px)",
                md: "50%",
                lg: "40%",
                xl: "30%",
              },
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                style={{ fontWeight: "600" }}
              >
                {type === "add-main" || type === "add-sub"
                  ? type === "add-main"
                    ? "Add Category"
                    : `Add Sub Category of ${lable}`
                  : type === "update-main" || type === "update-sub"
                  ? type === "update-main"
                    ? "Update Category"
                    : `Update Sub Category of ${lable}`
                  : ""}
              </Typography>
              <CloseRoundedIcon
                style={{ color: "#252525", cursor: "pointer" }}
                onClick={() => handleClose()}
              />
            </Box>
            <Box className="flexCenter">
              <Box sx={{ width: { md: "550px" } }}>
                <Box>
                  <label
                    htmlFor="title"
                    style={{ fontSize: "14px", paddingLeft: "0px" }}
                  >
                    Title
                  </label>
                  <TextField
                    type="text"
                    id="title"
                    name="title"
                    autoFocus
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.title?.trimStart()}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    variant="outlined"
                    fullWidth
                    focused
                    sx={{
                      margin: "0.25rem 0 0 0",
                      ".MuiInputBase-root": {
                        borderRadius: "15px",
                        background: "#FD5D2914",
                        color: "#000",
                        border: "1px solid #FD5D2966",
                      },
                      ".MuiOutlinedInput-input": {
                        padding: "14px",
                      },
                      ".MuiInputBase-root:hover": {
                        background: "#FF8862",
                        color: "#fff",
                      },
                      "& input[type='password']::-ms-reveal": {
                        display: "none",
                      },
                      "& input[type='password']::-ms-clear": {
                        display: "none",
                      },

                      ".MuiOutlinedInput-input:focus": {
                        outline: "none",
                      },
                      fieldset: {
                        border: "none",
                      },
                    }}
                    placeholder="Category Title"
                  />
                </Box>
                <Box
                  sx={{
                    fontSize: "12px",
                    px: 1.5,
                    letterSpacing: "0.03rem",
                    py: 0.25,
                    height: "15px",
                    color: "#d32f2f",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  {formik.touched.title &&
                    Boolean(formik.errors.title) &&
                    formik.errors.title}
                </Box>

                <Box
                  sx={{
                    display:
                      type === "add-main" || type === "update-main"
                        ? "block"
                        : "none",
                  }}
                >
                  <label
                    htmlFor="icon"
                    style={{ fontSize: "14px", paddingLeft: "0px" }}
                  >
                    Icon
                  </label>

                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      if (acceptedFiles && acceptedFiles.length > 0) {
                        const file = acceptedFiles[0];
                        formik.setFieldValue("icon", {
                          file: file,
                          path: URL.createObjectURL(file),
                        });
                      }
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <input
                            {...getInputProps()}
                            accept="image/png, image/jpeg"
                          />
                          <Box
                            sx={{
                              border: "1px solid #FD5D2966",
                              px: 4,
                              py: 5,
                              borderRadius: "15px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              background: "#FD5D2914",
                              "&:hover": {
                                cursor: "pointer",
                              },
                            }}
                          >
                            {type === "add-main" &&
                            formik.values.icon === null ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <img
                                  src={icon}
                                  alt=""
                                  style={{ height: "50px", width: "50px" }}
                                />
                                <p style={{ color: "#00000099" }}>
                                  Drag 'n' drop here, or click to select icon
                                </p>
                              </Box>
                            ) : formik?.values?.icon?.path ? (
                              <img
                                src={formik?.values?.icon?.path}
                                alt=""
                                style={{ height: "50px", width: "50px" }}
                              />
                            ) : (
                              <img
                                src={apiList?.baseURL + formik?.values?.icon}
                                alt=""
                                style={{
                                  height: "50px",
                                  width: "50px",
                                }}
                              />
                            )}
                          </Box>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </Box>
                <Box
                  sx={{
                    fontSize: "12px",
                    px: 1.5,
                    letterSpacing: "0.03rem",
                    py: 0.25,
                    height: "15px",
                    color: "#d32f2f",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  {formik.touched.icon &&
                    Boolean(formik.errors.icon) &&
                    formik.errors.icon}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Button
                variant="contained"
                disabled={formik.isSubmitting}
                onClick={formik.handleSubmit}
                sx={{
                  width: "100%",
                  maxWidth: "330px",
                  textTransform: "none",
                  borderRadius: "15px",
                  fontSize: "17px",
                  background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
                  "&:hover": {
                    background: "#00075",
                  },
                  padding: "12px",
                }}
              >
                {type === "add-sub" || type === "add-main"
                  ? formik.isSubmitting
                    ? "Submitting..."
                    : "Submit"
                  : formik.isSubmitting
                  ? "Updating..."
                  : "Update"}
                &nbsp;
                {/* {!formik.isSubmitting && <EastRoundedIcon />} */}
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
